import axios from "axios";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../Pagination";
export default function AuthorPost() {
  const userID = localStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [authorPost, setAuthorPost] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");

  useEffect(() => {
    const fetchAuthorNewsById = async (authorId, page, limit) => {
      try {
        const response = await axios.get(
          `${apiUrl}news/byauthor/${authorId}?page=${page || 1}&limit=${
            limit || 20
          }`
        );
        if (response) {
          setAuthorPost(response.data.data);
          setTotalPage(response.data.totalPages);
        }

        console.log(response, "author response");
      } catch (error) {
        console.error("Error fetching author news:", error.message);
      }
    };
    fetchAuthorNewsById(userID, currentPage);
  }, [userID, currentPage]);
  console.log(userID, authorPost, totalPage, "useredtfyguhjinkm");
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="manage-Posts">
      <div className="card">
        <div className="card-header">Manage Post</div>
        <div className="card-body">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Category </th>
                <th scope="col">Heading</th>
                <th scope="col">URL</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {authorPost?.map((item, i) => (
                <tr key={i}>
                  <td>{item.categoryid.displayName}</td>
                  <td>{item.heading}</td>
                  <td>{item.url}</td>
                  <td>{moment(item.publishDate).format("DD-MM-YYYY")}</td>
                  <td>
                    <Link to={`/editnews/${item._id}`}>
                      <button
                        className="action-button"
                        //  onClick={() => handleEdit(category)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPage}
            prevPage={() => handlePageChange(currentPage - 1)}
            news={authorPost}
            nextPage={() => handlePageChange(currentPage + 1)}
          />
        </div>
      </div>
    </div>
  );
}
