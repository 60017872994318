import React, { useEffect, useState } from "react";

const EditorComponent = ({ initialEditorData }) => {
    const [editorData, setEditorData] = useState(initialEditorData);
    const apiKey = process.env.REACT_APP_COEDITORX_API_KEY;
    const token = localStorage.getItem("token");

    // Old Logic
    // useEffect(() => {
    //     const handleMessage = (event) => {
    //         if (event.origin !== "http://localhost:3001") return;
    //         try {
    //             if (event.data && typeof event.data !== "string") {
    //                 setEditorData("");
    //             } else {
    //                 setEditorData(event.data);
    //             }
    //         } catch (error) {
    //             console.error("Error parsing message data:", error);
    //         }
    //     };
    //     window.addEventListener("message", handleMessage);
    //     return () => {
    //         window.removeEventListener("message", handleMessage);
    //     };
    // }, []);

    // New Logic
    useEffect(() => {
        const handleMessage = (event) => {
            // if (event.origin !== "https://kailashjs.com") return;
            // if (event.origin !== "http://localhost:3001") return;
            try {
                if (event.data && typeof event.data !== "string") {
                    setEditorData(initialEditorData);
                } else {
                    setEditorData(event.data);
                }
            } catch (error) {
                console.error("Error parsing message data:", error);
            }
        };

        window.addEventListener("message", handleMessage);

        // Send token and initial data to the iframe once it has loaded
        const iframe = document.getElementById("editor-iframe");
        iframe.onload = () => {
            // iframe.contentWindow.postMessage({ token, editorData }, "https://kailashjs.com");
            iframe.contentWindow.postMessage({ token, editorData }, "http://localhost:3001");
        };

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [token, editorData]);

    const saveData = () => {
        console.log("Saving data:", editorData);
    };

    return (
        <div>
            <iframe
                id="editor-iframe"
                src="http://localhost:3001/ep-coeditorx.html?apiKey=esAfzZsDsdftyf459845"
                // src={`https://kailashjs.com/ep-coeditorx.html?token=${apiKey}`}
                width="100%"
                height="500"
                title={editorData}
            ></iframe>
            {/* <div>
                <h3>Editor Data:</h3>
                <pre>{editorData}</pre>
            </div> */}
            {/* <button onClick={saveData}>Console Log Data</button> */}
        </div>
    );
};

export default EditorComponent;
