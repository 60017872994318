import React, { useState, useEffect } from "react";
import "../subCategory/subCategory.css";
import {
  postSecondarySubCategoryAdd,
  getSecondarySubCategorys,
  postSecondarySubCategoryUpdate,
} from "../../../store/Action/secondarySubCategoryAction";
import { getSubCategoryByParent } from "../../../store/Action/subCategoryAction";
import { getCategory } from "../../../store/Action/categoryAction";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddSecondarySubCategory() {
  const [inputValue, setInputValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1"); // Default value
  const [category, setCategory] = useState("");
  const [subCatOrder, setSubCatOrder] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [inputId, setInputId] = useState("");
  
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [formData, setFormData] = useState("");
  const dispatch = useDispatch();

  const id = useParams();
  const navigate = useNavigate();

  const { categoryData, subCategoryData } = useSelector((state) => state);
  const [parentCategory, setParentCategory] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  useEffect(() => {
    // Fetch the sub-categories based on the selected parent category
    if (parentCategory) {
      dispatch(getSubCategoryByParent(parentCategory));
    }
  }, [parentCategory, dispatch]);
  console.log(subCategoryData, "dddd");

  const handleParentCategoryChange = (selectedParentCategory) => {
    setParentCategory(selectedParentCategory);
  };
  // console.log(categoryData.data, "categoryData");
  // console.log(category, "parent");

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleOrderChange = (event) => {
    setSubCatOrder(event.target.value);
  };

  const handleSeoUrl = (event) => {
    let value = event.target.value;

    // Remove special characters except hyphen
    value = value.replace(/[^a-zA-Z0-9- ]/g, "");

    // Replace spaces with hyphens
    value = value.replace(/\s+/g, "-");

    // Convert to lowercase
    value = value.toLowerCase();
    setSeoUrl(value);
  };
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleCategoryChange = (selectedCategoryId) => {
    setCategory(selectedCategoryId);
    // setSelectedCategory(event.target.value);
  };
  const handleSeoTitleChange = (event) => {
    setSeoTitle(event.target.value);
  };
  const handleSeoDescriptionChange = (event) => {
    setSeoDescription(event.target.value);
  };
  const handleSeoKeywordsChange = (event) => {
    setSeoKeywords(event.target.value);
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault(); // Prevents the default form submission behavior

  //   // Dispatch the action with the form data
  //   const response = dispatch(
  //     postSubCategoryAdd({
  //       parent: category,
  //       displayName: inputValue,
  //       status: selectedStatus,
  //     })
  //   );

  //   if (response) {
  //     setInputValue("");
  //     // setCategory("");
  //     // toast.sucess('')
  //     console.log(response,"resp")
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(seoUrl){
      try {
        const data = {
          subParent: category,
          parent: parentCategory,
          secondDisplayName: {
            name: inputValue,
            seoUrl: seoUrl,
            seoTitle: seoTitle,
            seoDescription: seoDescription,
            seoKeywords: seoKeywords,
            secSubCategoryOrder: parseInt(subCatOrder),
          },
  
          // seoUrl: seoUrl,
          status: selectedStatus,
        };
  
        if (id.id) {
          // const dataId = 
          data.secondDisplayName._id = inputId
          await dispatch(postSecondarySubCategoryUpdate(id.id, data));
          setCategory("");
          navigate("/managesecondarysubcategory");
        } else {
          await dispatch(postSecondarySubCategoryAdd(data));
          // setCategory("");
          navigate("/managesecondarysubcategory");
        }
  
        setInputValue("");
        setSeoUrl("");
        selectedStatus("1");
        // setCategory("")
        
      } catch (error) {}
      // navigate("/managesecondarysubcategory");
    }else{
      toast.error("Seo URl in required")
    }
  };

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const resp = await dispatch(getSecondarySubCategorys(id.id));
        if (resp) {
          console.log(resp, " : llll");
          setParentCategory(resp?.data?.data?.parent);
          setInputValue(resp.data.data.secondDisplayName.name);
          setInputId(resp.data.data.secondDisplayName._id);
          setSeoUrl(
            resp.data.data?.secondDisplayName.seoUrl
              ? resp.data.data?.secondDisplayName.seoUrl
              : ""
          );
          setSelectedStatus(resp.data.data.status);
          setSubCatOrder(resp.data.data.secondDisplayName.secSubCategoryOrder ? resp.data.data.secondDisplayName.secSubCategoryOrder :"")
          setCategory(resp?.data?.data?.subParent);
          setSeoTitle(resp.data.data.secondDisplayName.seoTitle ? resp.data.data.secondDisplayName.seoTitle :"")
          setSeoDescription(resp.data.data.secondDisplayName.seoDescription ? resp.data.data.secondDisplayName.seoDescription :"")
          setSeoKeywords(resp.data.data.secondDisplayName.seoKeywords ? resp.data.data.secondDisplayName.seoKeywords :"")
          // setSelectedCategory(resp.data.data.parent)
        }
      } catch (error) {
        // Handle error, e.g., show an error toast message
      }
    };

    fetchSubCategory();
  }, [dispatch, id.id]);
  // console.log(id,"id")

  return (
    <div>
      <div className="">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id
              ? "Edit Secondary Sub Category"
              : "Add Secondary Sub Category"}
          </div>
          <div className="card-body">
            <form>
              <div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">
                    Select Parent Category <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={parentCategory}
                    onChange={(event) => {
                      handleParentCategoryChange(event.target.value);
                    }}
                  >
                    <option value="">Select Parent Category</option>
                    {categoryData?.data?.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.displayName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect2">
                    Select Sub Category <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    value={category}
                    onChange={(event) => {
                      handleCategoryChange(event.target.value);
                    }}
                  >
                    <option value="">Select Sub Category</option>
                    {subCategoryData &&
                      subCategoryData.data.subcategories?.map((item) => (
                        <>
                          <option key={item._id} value={item._id}>
                            {item.displayName}
                          </option>
                        </>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Sec Sub Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Sub Category"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">
                  Add Sec Sub Category Order No.
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Sub Category Order"
                  value={subCatOrder}
                  onChange={handleOrderChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">SEO Url <span className="req-feild">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add SEO Url"
                  value={seoUrl}
                  onChange={handleSeoUrl}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo title"
                  value={seoTitle}
                  onChange={handleSeoTitleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo description"
                  value={seoDescription}
                  onChange={handleSeoDescriptionChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Keywords</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo seokeywords"
                  value={seoKeywords}
                  onChange={handleSeoKeywordsChange}
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">Select Status</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div> */}
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">Select Status</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <button
                type="button"
                className="btn  btn-lg"
                onClick={handleSubmit}
              >
                {id.id ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
