import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  getCollegeData,
  deleleteCollege,
} from "../../../store/Action/admissionAction";
import Pagination from "../../../Pagination";

export default function ManageAdmission() {
  const dispatch = useDispatch();
  const [college, setCollege] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletes, setDelete] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalPage,setTotalPage]= useState('');
  const itemsPerPage = 12;
  const queryParams = {
     page: currentPage,
     perPage: itemsPerPage,
     limit: itemsPerPage,
     searchQuery:searchQuery,

  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const response = dispatch(deleleteCollege(id));
      if (response) {
        setDelete(response);
      }
    }
  };
  
  useEffect(() => {
    const fetchNews = async (queryParams) => {
      try {
        const response = await dispatch(getCollegeData(queryParams));
        if (response) {
          setCollege(response.data); // Update with the correct field name from your API response
          setTotalPage(response.totalPages);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchNews(queryParams);
  }, [dispatch, deletes,currentPage,searchQuery]);
  console.log(college, "college");
  // Logic to handle pagination controls
  // const nextPage = () => {
  //   if (college.length === itemsPerPage) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const prevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handelSearch = (e) => {
    setSearchQuery(e.target.value);
    console.log(e)
  };
  // Function to handle shifting backward by 10 pages
  const handleShiftBackward = () => {
    setCurrentPage(Math.max(1, currentPage - 10));
  };

  // Function to handle shifting forward by 10 pages
  const handleShiftForward = () => {
    setCurrentPage(Math.min(totalPage, currentPage + 10));
  };

  // Function to handle navigating to a specific page
  const handleGoToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="card">
        <div className="card-header">Manage Admission</div>
        <div className="card-body">
          <table className="table">
          <div className="search-container">
    <label><b>Search</b></label>
    <input
      type="text"
      value={searchQuery}
      // onChange={(e) => setSearchQuery(e.target.value)}
      onChange={handelSearch}
      placeholder="Search college by Name..."
      className="form-control"
    />
  </div> 
            <thead className="thead-dark">
              <tr>
                <th scope="col">College Name</th>
                <th scope="col">College Type</th>
                <th scope="col">City</th>
                <th scope="col">Rank</th>
                <th scope="col">Fees</th>
                <th scope="col">Courses</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {college.map((item) => (
                <tr key={item._id}>
                  {/* <td scope="row">1</td> */}
                  <td>{item.collageName}</td>
                  <td>{item.collageType}</td>
                  <td>{item.city}</td>
                  <td>{item.rank}</td>
                  <td>{item.fee}</td>
                  <td>{item.course}</td>
                  <td>{item.status}</td>
                  <td className="d-flex">
                    <Link to={`/editadmission/${item._id}`}>
                      <button
                        className="action-button"
                        //  onClick={() => handleEdit(item)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>

                    <button
                      className="action-button"
                      onClick={() => handleDelete(item._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                  {/* Add action buttons here */}
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
            <div className="pagination">
              <button
                className="pagination-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="current-page">{currentPage}</span>

              <button
                className="pagination-btn"
                onClick={nextPage}
                disabled={college.length < itemsPerPage}
              >
                {college.length < itemsPerPage ? "" : "Next"}
              </button>
            </div>
          </div> */}
        </div>
        <Pagination
        currentPage={currentPage}
        totalPages={totalPage}
        prevPage={() => handlePageChange(currentPage - 1)}
        news={college}
        nextPage={() => handlePageChange(currentPage + 1)}
        shiftBackward={handleShiftBackward}
        shiftForward={handleShiftForward}
        goToPage={handleGoToPage}
      />
      </div>
      </div>

      
    
  );
}
