const clearAuthData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
}
export const clearLocalStorage = () => {
    try {
        clearAuthData();
        console.log('Local Storage cleared successfully.');
    } catch (error) {
        console.error('Error clearing Local Storage:', error);
    }
};
