import { combineReducers } from "redux";
import categoryReducer from "./category";
import subCategoryReducer from "./subCategory";
import secondarySubCategoryReducer from "./secondarySubCategory";
import { subNextCategoryReducer } from "./nextSecondarySubCategory";
import { newSubCategoryReducer } from "./nextSecondarySubCategory";
import previewReducer from "./preview";
const rootReducer = combineReducers({
  categoryData: categoryReducer,
  subCategoryData: subCategoryReducer,
  secondarySubCategoryData: secondarySubCategoryReducer,
  subNextCategoryReducer: subNextCategoryReducer,
  newSubCategory: newSubCategoryReducer,
  previewData: previewReducer,
});

export default rootReducer;
