import { ALERT, GET_VIDEOS, REMOVE_ALERT } from "./actionType";
import {
  getDataAPI,
  postDataAPI,
  deleteData,
  patchDataApi,
} from "../../services/apiWrapper";

import { toast } from "react-toastify";

export const postVideoAdd = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postDataAPI(`videos`, obj));
    if (response) {
      toast.success("Video Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};



export const deleteVideo = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(deleteData(`videos/${id}`));
    if (response) {
      toast.success("videos deleted Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getSingleVideo = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`videos/${id}`));
    if (response) {
      return response;
    }
  } catch (error) {
    // toast.error('please fill all field');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};


export const postVideoUpdate = (id, data) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(patchDataApi(`videos/${id}`, data));
    if (response) {
      toast.success("videos updated Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

// export const getVideos = () => async (dispatch) => {
//   try {
//     dispatch({
//       type: ALERT,
//       payload: { loading: true },
//     });

//     // Include the 'id' as a parameter in the API URL
//     const response = await dispatch(getDataAPI(`videos`));

//     dispatch({ type: GET_VIDEOS, payload: response });
//     console.log(response, "videos");
//   } catch (error) {
//     // dispatch({ type: ALERT, payload: { error: error.message } });
//     console.warn(error);
//     setTimeout(() => {
//       dispatch({ type: REMOVE_ALERT });
//     }, 5000);
//   }
// };







// export const postVideoManage = (queryParams) => async (dispatch) => {
//   try {
//     // Assuming queryParams is an object with keys like "currentPage" and "itemsPerPage"
//     const queryParamsString = new URLSearchParams(queryParams).toString();

//     const response = await dispatch(
//       postDataAPI(`videos/getvideos?${queryParamsString}`)
//     );

//     if (response) {
//       // toast.success('videos Added successfully');
//       return response;
//     }
//   } catch (error) {
//     //   toast.error('Please fill all fields');
//     return false;
//   }
// };