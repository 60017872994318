// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import "./news.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
// import {
//   deleleteNews,
//   getNews,
//   getNewsByCategory,
// } from "../../../store/Action/newsAction";
// import Pagination from "../../../Pagination";
// import moment from "moment";
// import { getCategory } from "../../../store/Action/categoryAction";
// import { getSubCategoryByParent } from "../../../store/Action/subCategoryAction";
// import { getNextSecondarySubCategoryByParent } from "../../../store/Action/nextSecondarySubCategoryAction";
// import { RiDraftLine } from "react-icons/ri";
// import { getNewSubCategoryByParent } from "../../../store/Action/nextSecondarySubCategoryAction";
// import axios from "axios";
// export default function ManageNews() {
//   const dispatch = useDispatch();
//   const [news, setNews] = useState([]);
//   const [deletes, setDelete] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPage,setTotalPage]= useState('');
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [parentCategory, setParentCategory] = useState("");
//   const [filterData, setFilterData] = useState("");
//   const [draftedNews, setDraftedNews] = useState(false);
//   const [year, setYear] = useState("");
//   const [subParentCategory, setSubParentCategory] = useState("");
//   const [searchQuery, setSearchQuery] = useState('');

//   const [nextCategory, setNextCategory] = useState({ _id: "", name: "" });
//   const [newSubCategoryState, setNewSubCategoryState] = useState({
//     _id: "",
//     name: "",
//   });
//   const itemsPerPage = 12; // Change this to the desired number of items per page
//   const {
//     categoryData,
//     subCategoryData,
//     subNextCategoryReducer,
//     newSubCategory,
//   } = useSelector((state) => state);

//   // Logic to calculate total pages
//   // const queryParams = { page: currentPage, perPage: itemsPerPage };
//   const handleParentCategoryChange = (selectedParentCategory) => {
//     setParentCategory(selectedParentCategory);
//     setSubParentCategory('');
//     setNextCategory('')

//   };
//   const handleSubParentCategoryChange = (selectedSubParentCategory) => {
//     setSubParentCategory(selectedSubParentCategory);
//   };

//   const handleNextCategoryChange = (selectedCategoryId) => {
//     console.log(selectedCategoryId, "trytr");
//     // Find the selected category object based on the ID
//     const selectedCategory = subNextCategoryReducer.data.find(
//       (item) => item.secondDisplayName._id === selectedCategoryId
//     );

//     // Update the state with the selected category object
//     setNextCategory({
//       _id: selectedCategoryId,
//       name: selectedCategory ? selectedCategory.secondDisplayName.name : "",
//     });
//     console.log(nextCategory, "ttt");
//   };

//   const handleNewSubCategoryChange = (selectedCategoryId) => {
//     console.log(selectedCategoryId, "newsubcategory");
//     console.log("newSubCategory.data:", newSubCategory.data);
//     const selectedCategory = newSubCategory?.data?.find(
//       (item) => item.nextSecondDisplayName._id === selectedCategoryId
//     );
//     console.log("Selected Category:", selectedCategory);
//     // Update the state with the selected category object
//     setNewSubCategoryState(
//       (prevState) => ({
//         ...prevState,
//         _id: selectedCategoryId,
//         name: selectedCategory
//           ? selectedCategory.nextSecondDisplayName?.name
//           : "",
//       }),
//       () => {
//         console.log(newSubCategoryState, "hola amigo");
//       }
//     );
//   };

//   const queryParams = {
//     searchQuery:searchQuery,
//     page: currentPage,
//     perPage: itemsPerPage,
//     isDraft: draftedNews,
//     startDate:startDate,
//     endDate:endDate,
//   };
//   // Fetch News and total count from API or any data source
//   const handleDelete = (id) => {
//     if (window.confirm("Are you sure you want to delete this category?")) {
//       const response = dispatch(deleleteNews(id));
//       if (response) {
//         setDelete(response);
//       }
//     }
//   };
//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         console.log(queryParams, "queryParams");
//         const response = await dispatch(getNews(queryParams));
//         if (response) {
//           setNews(response.data);
//            // Update with the correct field name from your API response
//           // setTotalCount(response.data.totalCount);
//           setTotalPage(response.totalPages)
//         }
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };

//     fetchNews();
//   }, [dispatch, deletes, currentPage, draftedNews,startDate,endDate,searchQuery]);
//   console.log(news, "news");
//   const showDrafted = () => {
//     setDraftedNews(!draftedNews);
//   };
//   // Logic to handle pagination controls

//   // Handler function to update startDate state
//   const handleStartDateChange = (e) => {
//     setStartDate(e.target.value);
//   };
//   const handelSearch = (e) => {
//     setSearchQuery(e.target.value);
//     console.log(e)
//   };

//   // Handler function to update endDate state
//   const handleEndDateChange = (e) => {
//     setEndDate(e.target.value);
//   };
//   // use effect for filter

//   useEffect(() => {
//     console.log(parentCategory, "parentCategory");

//     const fetchData = async () => {
//       try {
//         const params = {
//           categoryid: parentCategory,
//           subCategoryid: subParentCategory,
//           secondDisplayNameId: nextCategory?._id,
//           nextSecondDisplayNameId: newSubCategoryState?._id,
//           // year, // Uncomment this line if 'year' is defined
//           page: currentPage,
//           limit: itemsPerPage,
//         };

//         const response = await dispatch(getNewsByCategory(params));
//         console.log("API Response:", response);

//         if (response) {
//           setFilterData(response.data);
//           console.log("API Response:", response.data);

//         }
//       } catch (error) {

//         console.error("Error fetching news:", error);
//       }
//     };

//     if (parentCategory) {
//       fetchData();
//     }
//   }, [
//     parentCategory,
//     subParentCategory,
//     nextCategory?._id,
//     newSubCategoryState?._id,
//     currentPage,
//     itemsPerPage,
//     dispatch,
//   ]);

//   useEffect(() => {
//     dispatch(getCategory());
//     if (parentCategory) {
//       dispatch(getSubCategoryByParent(parentCategory));
//     }

//     if (subParentCategory) {
//       dispatch(getNextSecondarySubCategoryByParent(subParentCategory));
//     }
//     if (nextCategory?._id) {
//       // console.log(nextCategory?._id,"inuseeffect")
//       dispatch(getNewSubCategoryByParent(nextCategory?._id));
//     }
//   }, [parentCategory, dispatch, subParentCategory, nextCategory?._id]);

//   // Function to handle page navigation
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Function to handle shifting backward by 10 pages
//   const handleShiftBackward = () => {
//     setCurrentPage(Math.max(1, currentPage - 10));
//   };

//   // Function to handle shifting forward by 10 pages
//   const handleShiftForward = () => {
//     setCurrentPage(Math.min(totalPage, currentPage + 10));
//   };

//   // Function to handle navigating to a specific page
//   const handleGoToPage = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };
//   return (
//     <div>
//       <div className="card">

//         <div className="card-header">Manage News</div>

//         <div className="filter-container">
//           <div>
//         <label><b>start Date</b></label>
//         <input type="date" value={startDate} onChange={handleStartDateChange} class="form-control"  />
// <label><b>End Date</b></label>

// <input type="date" value={endDate} onChange={handleEndDateChange}  class="form-control"/>
// </div>

// <div className="search-container">
//     <label><b>Search</b></label>
//     <input
//       type="text"
//       value={searchQuery}
//       // onChange={(e) => setSearchQuery(e.target.value)}
//       onChange={handelSearch}
//       placeholder="Search news..."
//       className="form-control"
//     />
//   </div>
// <div >
//         <label>
//           <b>
//             Find Category
//             </b>
//           </label>

//           <div className="form-group">
//             <select
//               className="form-control"
//               id="exampleFormControlSelect1"
//               value={parentCategory}
//               onChange={(event) => {
//                 // handleCategoryChange(event.target.value);
//                 handleParentCategoryChange(event.target.value);
//               }}
//             >
//               <option value="">Select Category</option>
//               {categoryData?.data?.map((item, i) => (
//                 <option key={i} value={item._id}>
//                   {item.displayName}
//                 </option>
//               ))}
//             </select>
//           </div>
//           {parentCategory && (
//             <div className="form-group">
//               <select
//                 className="form-control"
//                 id="exampleFormControlSelect2"
//                 value={subParentCategory}
//                 onChange={(event) => {
//                   // handleCategoryChange(event.target.value);
//                   handleSubParentCategoryChange(event.target.value);
//                 }}
//               >
//                 <option value="">Select Sub Category</option>
//                 {subCategoryData &&
//                   subCategoryData.data.subcategories?.map((item) => (
//                     <>
//                       <option key={item._id} value={item._id}>
//                         {item.displayName}
//                       </option>
//                     </>
//                   ))}
//               </select>
//             </div>
//           )}
//           {subParentCategory && (
//             <div className="form-group">
//               <select
//                 className="form-control"
//                 id="exampleFormControlSelect2"
//                 // value={nextCategory?.secondDisplayName?._id}
//                 value={nextCategory?._id}
//                 onChange={(event) => {
//                   handleNextCategoryChange(event.target.value);
//                 }}
//               >
//                 {" "}
//                 <option value="">Secondary Sub Category</option>
//                 {subNextCategoryReducer &&
//                   subNextCategoryReducer.data?.map((item) => (
//                     <>
//                       <option
//                         key={item.secondDisplayName._id}
//                         value={item.secondDisplayName._id}
//                       >
//                         {item.secondDisplayName.name}
//                       </option>{" "}
//                     </>
//                   ))}
//               </select>
//             </div>
//           )}
//           {nextCategory?._id && (
//             <div className="form-group">

//               <select
//                 className="form-control"
//                 id="exampleFormControlSelect2"
//                 // value={newSubCategoryState.nextSecondDisplayName?._id}
//                 value={newSubCategoryState?._id}
//                 onChange={(event) => {
//                   handleNewSubCategoryChange(event.target.value);
//                 }}
//               >
//                 <option value="">New Sub Category</option>
//                 {newSubCategory &&
//                   newSubCategory.data?.map(
//                     (item) =>
//                       // Check if item.nextSecondDisplayName is not an empty object and has a non-empty name
//                       item.nextSecondDisplayName &&
//                       Object.keys(item.nextSecondDisplayName).length > 0 &&
//                       item.nextSecondDisplayName.name !== "" && (
//                         <option
//                           key={item._id}
//                           value={item.nextSecondDisplayName?._id}
//                         >
//                           {item.nextSecondDisplayName?.name}
//                         </option>
//                       )
//                   )}
//               </select>
//             </div>
//           )}
// </div>
//            <div className="toggle-container">
//             <input
//               type="checkbox"
//               id="toggle"
//               className="toggle-checkbox"
//               checked={draftedNews}
//               onChange={showDrafted}
//               // onChange={toggleDrafted}
//             />
//             <></>
//             <label
//               htmlFor="toggle"
//               className="toggle-label"
//               style={{ width: "160px" }}
//             >
//               <span className="toggle-text">
//                 {!draftedNews ? "Show Drafted News" : "Hide Drafted News"}
//               </span>
//             </label>
//           </div>
//          </div>
//           <div className="card-body">
//             <table className="table">
//               <thead className="thead-dark">
//                 <tr>
//                   <th scope="col">Category</th>
//                   <th scope="col">Heading</th>
//                   <th scope="col">author</th>
//                   <th scope="col">Url</th>
//                   <th scope="col">Description</th>
//                   <th scope="col">Publish Date</th>
//                   <th scope="col">Draft</th>
//                   <th scope="col">Action</th>
//                 </tr>
//               </thead>

//               <tbody>
//                 {/* {news
//                   .filter((item) => item.isDraft === true)
//                   .map((item) => (

//                     <tr key={item._id}>

//                       <td className="news-items">
//                         {item.categoryid?.displayName}
//                       </td>

//                       <td className="news-items">{item.heading}</td>
//                       <td className="news-items">{item?.author?.name}</td>
//                       <td className="news-items">{item.url}</td>
//                       <td className="news-description">
//                         {item.description.length > 120
//                           ? `${item.description.slice(0, 120)} ...`
//                           : `${item.description}`}
//                       </td>
//                       <td className="news-items">
//                         <small>
//                           {moment(item.publishDate).format("DD-MM-YYYY")}
//                         </small>
//                       </td>
//                       <td className="news-items">

//                         {item.isDraft ? (
//                           <Link to={`/editnews/${item._id}`}>
//                             <RiDraftLine className="fs-2" />
//                           </Link>
//                         ) : (
//                           ""
//                         )}
//                       </td>
//                       <td className="d-flex">
//                         <Link to={`/editnews/${item._id}`}>
//                           <button
//                             className="action-button"

//                           >
//                             <FontAwesomeIcon icon={faEdit} />
//                           </button>
//                         </Link>

//                         <button
//                           className="action-button"
//                           onClick={() => handleDelete(item._id)}
//                         >
//                           <FontAwesomeIcon icon={faTrash} />
//                         </button>
//                       </td>

//                     </tr>
//                   ))} */}
//                                {news.length > 0 ? (
//   news.map((item) => (
//     <tr key={item._id}>
//       <td className="news-items">{item.categoryid?.displayName}</td>
//       <td className="news-items">{item.heading}</td>
//       <td className="news-items">{item?.author?.name}</td>
//       <td className="news-items">{item.url}</td>
//       <td className="news-description">
//         {item.description.length > 120
//           ? `${item.description.slice(0, 120)} ...`
//           : `${item.description}`}
//       </td>
//       <td className="news-items">
//         <small>{moment(item.publishDate).format("DD-MM-YYYY")}</small>
//       </td>
//       <td className="news-items">
//         {item.isDraft ? (
//           <Link to={`/editnews/${item._id}`}>
//             <RiDraftLine className="fs-2" />
//           </Link>
//         ) : (
//           ""
//         )}
//       </td>
//       <td className="d-flex">
//         <Link to={`/editnews/${item._id}`}>
//           <button className="action-button">
//             <FontAwesomeIcon icon={faEdit} />
//           </button>
//         </Link>
//         <button
//           className="action-button"
//           onClick={() => handleDelete(item._id)}
//         >
//           <FontAwesomeIcon icon={faTrash} />
//         </button>
//       </td>
//     </tr>
//   ))
// ) : (
//   <tr>
//     <td colSpan="8" className="text-center">No data found</td>
//   </tr>
// )}

//               </tbody>
//             </table>

//           </div>

//  <Pagination
//         currentPage={currentPage}
//         totalPages={totalPage}
//         prevPage={() => handlePageChange(currentPage - 1)}
//         news={news}
//         nextPage={() => handlePageChange(currentPage + 1)}
//         shiftBackward={handleShiftBackward}
//         shiftForward={handleShiftForward}
//         goToPage={handleGoToPage}
//       />
//       </div>
//     </div>
//   );
// }
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./news.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  deleleteNews,
  getNews,
  getNewsByCategory,
} from "../../../store/Action/newsAction";
import Pagination from "../../../Pagination";
import moment from "moment";
import { getCategory } from "../../../store/Action/categoryAction";
import { getSubCategoryByParent } from "../../../store/Action/subCategoryAction";
import { getNextSecondarySubCategoryByParent } from "../../../store/Action/nextSecondarySubCategoryAction";
import { RiDraftLine } from "react-icons/ri";
import { getNewSubCategoryByParent } from "../../../store/Action/nextSecondarySubCategoryAction";
import axios from "axios";
export default function ManageNews() {
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const [news, setNews] = useState([]);
  const [deletes, setDelete] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [filterData, setFilterData] = useState("");
  const [draftedNews, setDraftedNews] = useState(false);
  const [year, setYear] = useState("");
  const [subParentCategory, setSubParentCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [nextCategory, setNextCategory] = useState({ _id: "", name: "" });
  const [newSubCategoryState, setNewSubCategoryState] = useState({
    _id: "",
    name: "",
  });
  const itemsPerPage = 12; // Change this to the desired number of items per page
  const {
    categoryData,
    subCategoryData,
    subNextCategoryReducer,
    newSubCategory,
  } = useSelector((state) => state);

  // Logic to calculate total pages
  // const queryParams = { page: currentPage, perPage: itemsPerPage };
  const handleParentCategoryChange = (selectedParentCategory) => {
    setParentCategory(selectedParentCategory);
    setSubParentCategory("");
    setNextCategory("");
  };
  const handleSubParentCategoryChange = (selectedSubParentCategory) => {
    setSubParentCategory(selectedSubParentCategory);
  };

  const handleNextCategoryChange = (selectedCategoryId) => {
    console.log(selectedCategoryId, "trytr");
    // Find the selected category object based on the ID
    const selectedCategory = subNextCategoryReducer.data.find(
      (item) => item.secondDisplayName._id === selectedCategoryId
    );

    // Update the state with the selected category object
    setNextCategory({
      _id: selectedCategoryId,
      name: selectedCategory ? selectedCategory.secondDisplayName.name : "",
    });
    console.log(nextCategory, "ttt");
  };

  const handleNewSubCategoryChange = (selectedCategoryId) => {
    console.log(selectedCategoryId, "newsubcategory");
    console.log("newSubCategory.data:", newSubCategory.data);
    const selectedCategory = newSubCategory?.data?.find(
      (item) => item.nextSecondDisplayName._id === selectedCategoryId
    );
    console.log("Selected Category:", selectedCategory);
    // Update the state with the selected category object
    setNewSubCategoryState(
      (prevState) => ({
        ...prevState,
        _id: selectedCategoryId,
        name: selectedCategory
          ? selectedCategory.nextSecondDisplayName?.name
          : "",
      }),
      () => {
        console.log(newSubCategoryState, "hola amigo");
      }
    );
  };

  const queryParams = {
    searchQuery: searchQuery,
    page: currentPage,
    perPage: itemsPerPage,
    isDraft: draftedNews,
    startDate: startDate,
    endDate: endDate,
    categoryId: parentCategory,
    subCategoryId: subParentCategory,
    secondDisplayNameId: nextCategory?._id || "",
  };
  console.log(queryParams, "queryParams");
  // Fetch News and total count from API or any data source
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      const response = dispatch(deleleteNews(id));
      if (response) {
        setDelete(response);
      }
    }
  };
  useEffect(() => {
    const fetchNews = async () => {
      try {
        console.log(queryParams, "queryParams");
        const response = await dispatch(getNews(queryParams));
        if (response) {
          setNews(response.data);
          // Update with the correct field name from your API response
          // setTotalCount(response.data.totalCount);
          setTotalPage(response.totalPages);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchNews();
  }, [
    dispatch,
    deletes,
    currentPage,
    draftedNews,
    startDate,
    endDate,
    searchQuery,
    parentCategory,
    subCategoryData,
    nextCategory?._id,
  ]);
  console.log(news, "news");
  const showDrafted = () => {
    setDraftedNews(!draftedNews);
  };
  // Logic to handle pagination controls

  // Handler function to update startDate state
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handelSearch = (e) => {
    setSearchQuery(e.target.value);
    console.log(e);
  };

  // Handler function to update endDate state
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  // use effect for filter

  useEffect(() => {
    console.log(parentCategory, "parentCategory");

    const fetchData = async () => {
      try {
        const params = {
          categoryid: parentCategory,
          subCategoryid: subParentCategory,
          secondDisplayNameId: nextCategory?._id,
          nextSecondDisplayNameId: newSubCategoryState?._id,
          // year, // Uncomment this line if 'year' is defined
          page: currentPage,
          limit: itemsPerPage,
        };

        const response = await dispatch(getNewsByCategory(params));
        console.log("API Response:", response);

        if (response) {
          setFilterData(response.data);
          console.log("API Response:", response.data);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    if (parentCategory) {
      fetchData();
    }
  }, [
    parentCategory,
    subParentCategory,
    nextCategory?._id,
    newSubCategoryState?._id,
    currentPage,
    itemsPerPage,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getCategory());
    if (parentCategory) {
      dispatch(getSubCategoryByParent(parentCategory));
    }

    if (subParentCategory) {
      dispatch(getNextSecondarySubCategoryByParent(subParentCategory));
    }
    if (nextCategory?._id) {
      // console.log(nextCategory?._id,"inuseeffect")
      dispatch(getNewSubCategoryByParent(nextCategory?._id));
    }
  }, [parentCategory, dispatch, subParentCategory, nextCategory?._id]);

  // Function to handle page navigation
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle shifting backward by 10 pages
  const handleShiftBackward = () => {
    setCurrentPage(Math.max(1, currentPage - 10));
  };

  // Function to handle shifting forward by 10 pages
  const handleShiftForward = () => {
    setCurrentPage(Math.min(totalPage, currentPage + 10));
  };

  // Function to handle navigating to a specific page
  const handleGoToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="card">
        <div className="card-header">Manage News</div>

        <div className="filter-container">
          <div>
            <label>
              <b>start Date</b>
            </label>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              class="form-control"
            />
            <label>
              <b>End Date</b>
            </label>

            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              class="form-control"
            />
          </div>

          <div className="search-container">
            <label>
              <b>Search</b>
            </label>
            <input
              type="text"
              value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
              onChange={handelSearch}
              placeholder="Search news..."
              className="form-control"
            />
          </div>
          <div>
            <label>
              <b>Find Category</b>
            </label>

            <div className="form-group">
              <select
                className="form-control"
                id="exampleFormControlSelect1"
                value={parentCategory}
                onChange={(event) => {
                  // handleCategoryChange(event.target.value);
                  handleParentCategoryChange(event.target.value);
                }}
              >
                <option value="">Select Category</option>
                {categoryData?.data?.map((item, i) => (
                  <option key={i} value={item._id}>
                    {item.displayName}
                  </option>
                ))}
              </select>
            </div>
            {parentCategory && (
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={subParentCategory}
                  onChange={(event) => {
                    // handleCategoryChange(event.target.value);
                    handleSubParentCategoryChange(event.target.value);
                  }}
                >
                  <option value="">Select Sub Category</option>
                  {subCategoryData &&
                    subCategoryData.data.subcategories?.map((item) => (
                      <>
                        <option key={item._id} value={item._id}>
                          {item.displayName}
                        </option>
                      </>
                    ))}
                </select>
              </div>
            )}
            {subParentCategory && (
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  // value={nextCategory?.secondDisplayName?._id}
                  value={nextCategory?._id}
                  onChange={(event) => {
                    handleNextCategoryChange(event.target.value);
                  }}
                >
                  {" "}
                  <option value="">Secondary Sub Category</option>
                  {subNextCategoryReducer &&
                    subNextCategoryReducer.data?.map((item) => (
                      <>
                        <option
                          key={item.secondDisplayName._id}
                          value={item.secondDisplayName._id}
                        >
                          {item.secondDisplayName.name}
                        </option>{" "}
                      </>
                    ))}
                </select>
              </div>
            )}
            {nextCategory?._id && (
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  // value={newSubCategoryState.nextSecondDisplayName?._id}
                  value={newSubCategoryState?._id}
                  onChange={(event) => {
                    handleNewSubCategoryChange(event.target.value);
                  }}
                >
                  <option value="">New Sub Category</option>
                  {newSubCategory &&
                    newSubCategory.data?.map(
                      (item) =>
                        // Check if item.nextSecondDisplayName is not an empty object and has a non-empty name
                        item.nextSecondDisplayName &&
                        Object.keys(item.nextSecondDisplayName).length > 0 &&
                        item.nextSecondDisplayName.name !== "" && (
                          <option
                            key={item._id}
                            value={item.nextSecondDisplayName?._id}
                          >
                            {item.nextSecondDisplayName?.name}
                          </option>
                        )
                    )}
                </select>
              </div>
            )}
          </div>
          <div className="toggle-container">
            <input
              type="checkbox"
              id="toggle"
              className="toggle-checkbox"
              checked={draftedNews}
              onChange={showDrafted}
              // onChange={toggleDrafted}
            />
            <></>
            <label
              htmlFor="toggle"
              className="toggle-label"
              style={{ width: "160px" }}
            >
              <span className="toggle-text">
                {!draftedNews ? "Show Drafted News" : "Hide Drafted News"}
              </span>
            </label>
          </div>
        </div>
        <div className="card-body">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Category</th>
                <th scope="col">Heading</th>
                <th scope="col">author</th>
                <th scope="col">Url</th>
                <th scope="col">Description</th>
                <th scope="col">Publish Date</th>
                <th scope="col">Draft</th>
                <th scope="col">Action</th>
              </tr>
            </thead>

            <tbody>
              {/* {news
                  .filter((item) => item.isDraft === true)
                  .map((item) => (
            

                    <tr key={item._id}>
                     
                      <td className="news-items">
                        {item.categoryid?.displayName}
                      </td>

                      <td className="news-items">{item.heading}</td>
                      <td className="news-items">{item?.author?.name}</td>
                      <td className="news-items">{item.url}</td>
                      <td className="news-description">
                        {item.description.length > 120
                          ? `${item.description.slice(0, 120)} ...`
                          : `${item.description}`}
                      </td>
                      <td className="news-items">
                        <small>
                          {moment(item.publishDate).format("DD-MM-YYYY")}
                        </small>
                      </td>
                      <td className="news-items">
                        
                        {item.isDraft ? (
                          <Link to={`/editnews/${item._id}`}>
                            <RiDraftLine className="fs-2" />
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="d-flex">
                        <Link to={`/editnews/${item._id}`}>
                          <button
                            className="action-button"
                            
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                        </Link>

                        <button
                          className="action-button"
                          onClick={() => handleDelete(item._id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    
                    </tr>
                  ))} */}
              {news.length > 0 ? (
                news.map((item) => (
                  <tr key={item._id}>
                    <td className="news-items">
                      {item.categoryid?.displayName}
                    </td>
                    <td className="news-items">{item.heading}</td>
                    <td className="news-items">{item?.author?.name}</td>
                    <td className="news-items">{item.url}</td>
                    <td className="news-description">
                      {item.description.length > 120
                        ? `${item.description.slice(0, 120)} ...`
                        : `${item.description}`}
                    </td>
                    <td className="news-items">
                      <small>
                        {moment(item.publishDate).format("DD-MM-YYYY")}
                      </small>
                    </td>
                    <td className="news-items">
                      {item.isDraft ? (
                        <Link to={`/editnews/${item._id}`}>
                          <RiDraftLine className="fs-2" />
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="d-flex">
                      <Link to={`/editnews/${item._id}`}>
                        <button className="action-button">
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </Link>
                      <button
                        className="action-button"
                        onClick={() => handleDelete(item._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          prevPage={() => handlePageChange(currentPage - 1)}
          news={news}
          nextPage={() => handlePageChange(currentPage + 1)}
          shiftBackward={handleShiftBackward}
          shiftForward={handleShiftForward}
          goToPage={handleGoToPage}
        />
      </div>
    </div>
  );
}
