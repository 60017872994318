import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import App from './App';
// import '@ckeditor/ckeditor5-build-classic/build/translations/en';
// import '@ckeditor/ckeditor5-build-classic/build/styles.css';
// import '@ckeditor/ckeditor5-build-classic/build/ckeditor.css'; // Note the corrected path
// import '@ckeditor/ckeditor5-build-classic/build/translations/en-au'
// import rootReducer from './store/reducers';
import rootReducer from './store/Reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const loadingMarkup = (
  <div className="py-4 text-center">
    <h4>Loading...</h4>
  </div>
);

const app = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
