import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  postNextSecondarySubCategoryManage,
  deleleteNextSecondarySubCategory,
} from "../../../store/Action/nextSecondarySubCategoryAction";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../subCategory/subCategory.css";

export default function ManageNextCategoryNew() {
  const dispatch = useDispatch();
  const [subCategories, setSubCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletes, setDelete] = useState(1);
  const itemsPerPage = 10; // Change this to the desired number of items per page

  // Logic to calculate total pages
  const queryParams = { page: currentPage, perPage: itemsPerPage };

  // Logic to handle pagination controls
  const nextPage = () => {
    if (subCategories.length === itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDelete = (categoryId) => {
    if (window.confirm("Are you sure you want to delete this Sub category?")) {
      const response = dispatch(deleleteNextSecondarySubCategory(categoryId));
      if (response) {
        setDelete(response);
      }
    }
  };
  // Fetch categories and total count from API or any data source
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await dispatch(
          postNextSecondarySubCategoryManage(queryParams)
        );
        if (response) {
          setSubCategories(response.data.category); // Update with the correct field name from your API response
          // setTotalCount(response.data.totalCount);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSubCategories();
  }, [dispatch, currentPage, deletes]);

  // console.log(subCategories, "abc");

  return (
    <div>
      <div className="card">
        <div className="card-header">Manage New Sub Category</div>
        <div className="card-body">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Category</th>
                <th scope="col">Sub Category </th>
                <th scope="col">Secondary Sub Category</th>
                <th scope="col">Next Secondary Sub Category</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {subCategories.map((item) => (
                <tr key={item._id}>
                  <td>
                    {item.parent ? item.parent.displayName : `Not Defined`}
                  </td>
                  <td>
                    {item.subParent
                      ? item.subParent.displayName
                      : `Not Defined`}
                  </td>
                  <td>{item.secondDisplayName?.name}</td>
                  <td>{item.nextSecondDisplayName?.name}</td>

                  <td>{item.status == 1 ? "Active" : "Inactive"}</td>
                  <td>
                    <Link to={`/editNextSecondarysubcategory/${item._id}`}>
                      <button
                        className="action-button"
                        //  onClick={() => handleEdit(item)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>

                    {/* <button
                      className="action-button"
                       onClick={() => handleDelete(item._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button> */}
                  </td>
                  {/* Add action buttons here */}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
            <div className="pagination">
              <button
                className="pagination-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="current-page">{currentPage}</span>

              <button
                className="pagination-btn"
                onClick={nextPage}
                disabled={subCategories.length < itemsPerPage}
              >
                {subCategories.length < itemsPerPage ? "" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
