import React, { useState, useEffect, useRef } from "react";
import "./video.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getSingleVideo,
  postVideoAdd,
  postVideoUpdate,
} from "../../../store/Action/videoAction";
// import {useState} from

export default function AddVideos() {
  const [videoUrl, setVideoUrl] = useState("");
  const [headline, setHeadline] = useState("");
  const [order, setOrder] = useState();
  const [s3ImageId, setS3ImageId] = useState("");
  const [selectedBannerImageFile, setSelectedBannerImageFile] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);

  const dispatch = useDispatch();
  // const history = useHistory();
  const id = useParams();
  const navigate = useNavigate();

  const handleUrlChange = (e) => {
    setVideoUrl(e.target.value);
  };
  const handleHeadlineChange = (e) => {
    setHeadline(e.target.value);
  };
  const handleOrderChange = (e) => {
    const number = parseInt(e.target.value);
    console.log(number, "number");
    setOrder(number);
  };
  const filePicker = useRef(null);
  console.log(filePicker, "filePicker");
  const onBannerImagePicked = (event) => {
    const fileInput = filePicker.current; // Access the filePicker ref
    const file = fileInput.files[0];
    const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        // File size exceeds the allowed limit
        alert("Logo image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        setBannerImagePreview("");
        setBannerFileSizeError(true);
      } else {
        setSelectedBannerImageFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setBannerImagePreview(reader.result);
          setBannerFileSizeError(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData();
      data.append("video_link", videoUrl);
      data.append("title", headline);
      data.append("order", order);
      if (selectedBannerImageFile) {
        data.append("thumbnailImage", selectedBannerImageFile);
      }

      if (id.id) {
        await dispatch(postVideoUpdate(id.id, data));
        navigate("/managevideos");
      } else {
        await dispatch(postVideoAdd(data));
        navigate("/managevideos");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const resp = await dispatch(getSingleVideo(id.id));
        if (resp) {
          console.log(resp, "resprespresp");
          setHeadline(resp?.data ? resp?.data.title : "");
          setVideoUrl(resp?.data ? resp?.data.video_link : "");
          setBannerFileSizeError(resp?.data ? resp?.data?.thumbnailImage : "");
          setS3ImageId(resp?.data ? resp?.data?.thumbnailImage : "");
          setOrder(resp?.data ? resp?.data?.order : "");
        }
      } catch (error) {}
    };

    fetchCategory();
  }, [dispatch, id.id]);

  return (
    <div>
      <div className="video-section">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id ? "Edit Videos" : "Add Videos"}
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Video URL Id </label>
                <input
                  type="url"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  value={videoUrl}
                  onChange={handleUrlChange}
                  placeholder="Add Video URL "
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Video HeadLine</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add HeadLine"
                  value={headline}
                  onChange={handleHeadlineChange}
                />
              </div>

              <div className="news-img-container">
                <div className="form-group ">
                  <label htmlFor="exampleText1">Upload Image</label>
                  <div className="add-img">
                    <button
                      type="button"
                      onClick={() => filePicker.current.click()} // Access the filePicker ref
                    >
                      + Image
                    </button>
                    <input
                      type="file"
                      ref={filePicker}
                      onChange={onBannerImagePicked}
                    />
                    {bannerFileSizeError && (
                      <div className="error-message">
                        File size exceeds the maximum allowed size.
                      </div>
                    )}
                  </div>
                  {bannerImagePreview || s3ImageId ? (
                    <div className="image-preview">
                      <img
                        src={
                          bannerImagePreview
                            ? bannerImagePreview
                            : `https://stage-api.iirfranking.com/s3-images/${s3ImageId}`
                        }
                        alt="test alt name"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Video Order</label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add order no 123"
                  value={order}
                  onChange={handleOrderChange}
                />
              </div>

              <button
                type="button"
                className="btn  btn-lg"
                onClick={handleSubmit}
              >
                {id.id ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
