import React, { useState, useEffect } from "react";
import "../subCategory/subCategory.css";
import {
  postNextSecondarySubCategoryAdd,
  getNextSecondarySubCategorys,
  postNextSecondarySubCategoryUpdate,
} from "../../../store/Action/nextSecondarySubCategoryAction";
import { getSubCategoryByParent } from "../../../store/Action/subCategoryAction";
import { getCategory } from "../../../store/Action/categoryAction";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getNextSecondarySubCategoryByParent } from "../../../store/Action/nextSecondarySubCategoryAction";
import { toast } from "react-toastify";
function AddNextCategoryNew() {
  const [inputValue, setInputValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1"); // Default value
  const [category, setCategory] = useState("");
  const [nextCategory, setNextCategory] = useState({ _id: "", name: "" });
  const [subCatOrder, setSubCatOrder] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [formData, setFormData] = useState("");
  const [inputId, setInputId] = useState("");
  const dispatch = useDispatch();

  const id = useParams();
  const navigate = useNavigate();

  const { categoryData, subCategoryData, subNextCategoryReducer } = useSelector(
    (state) => state
  );
  const [parentCategory, setParentCategory] = useState("");
  useEffect(() => {
    // Fetch the sub-categories based on the selected parent category
    if (parentCategory) {
      dispatch(getSubCategoryByParent(parentCategory));
    }
  }, [parentCategory, dispatch]);
  useEffect(() => {
    // Fetch the sub-categories based on the selected parent category
    if (category) {
      dispatch(getNextSecondarySubCategoryByParent(category));
    }
    console.log();
  }, [category, dispatch]);
  console.log(subCategoryData, "dddd");

  const handleParentCategoryChange = (selectedParentCategory) => {
    setParentCategory(selectedParentCategory);
  };
  // console.log(categoryData.data, "categoryData");
  // console.log(category, "parent");

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleOrderChange = (event) => {
    setSubCatOrder(event.target.value);
  };

  const handleSeoUrl = (event) => {
    let value = event.target.value;

    // Remove special characters except hyphen
    value = value.replace(/[^a-zA-Z0-9- ]/g, "");

    // Replace spaces with hyphens
    value = value.replace(/\s+/g, "-");

    // Convert to lowercase
    value = value.toLowerCase();
    setSeoUrl(value);
  };
  const handleSeoTitleChange = (event) => {
    setSeoTitle(event.target.value);
  };
  const handleSeoDescriptionChange = (event) => {
    setSeoDescription(event.target.value);
  };
  const handleSeoKeywordsChange = (event) => {
    setSeoKeywords(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleCategoryChange = (selectedCategoryId) => {
    setCategory(selectedCategoryId);
    // setSelectedCategory(event.target.value);
  };
  // const handleNextCategoryChange = (selectedCategoryId) => {
  //   setNextCategory(selectedCategoryId);
  //   // setSelectedCategory(event.target.value);
  // };
  const handleNextCategoryChange = (selectedCategoryId) => {
    console.log(selectedCategoryId, "trytr");
    // Find the selected category object based on the ID
    const selectedCategory = subNextCategoryReducer.data.find(
      (item) => item.secondDisplayName._id === selectedCategoryId
    );

    // Update the state with the selected category object
    setNextCategory({
      _id: selectedCategoryId,
      name: selectedCategory ? selectedCategory.secondDisplayName.name : "",
    });
    console.log(nextCategory, "ttt");
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault(); // Prevents the default form submission behavior

  //   // Dispatch the action with the form data
  //   const response = dispatch(
  //     postSubCategoryAdd({
  //       parent: category,
  //       displayName: inputValue,
  //       status: selectedStatus,
  //     })
  //   );

  //   if (response) {
  //     setInputValue("");
  //     // setCategory("");
  //     // toast.sucess('')
  //     console.log(response,"resp")
  //   }
  // };
  console.log(nextCategory, "nextCategory");
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (seoUrl) {
      try {
        const data = {
          subParent: category,
          parent: parentCategory,
          secondDisplayName: nextCategory,
          nextSecondDisplayName: {
            name: inputValue,
            seoUrl: seoUrl,
            seoTitle: seoTitle,
            seoDescription: seoDescription,
            seoKeywords: seoKeywords,
            secSubCategoryOrder: parseInt(subCatOrder),
          },
          status: selectedStatus,
        };
        console.log(data, "dataaaaaaa");
        if (id.id) {
          data.nextSecondDisplayName._id = inputId;
          await dispatch(postNextSecondarySubCategoryUpdate(id.id, data));
          setCategory("");
          navigate("/managenextsubcategory");
        } else {
          await dispatch(postNextSecondarySubCategoryAdd(data));
          // setCategory("");
          navigate("/managenextsubcategory");
        }

        setInputValue("");
        selectedStatus("1");
        // setCategory("")
      } catch (error) {}
    } else {
      toast.error("Seo URL is required");
    }
    // navigate("/managenextsubcategory");
  };

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const resp = await dispatch(getNextSecondarySubCategorys(id.id));
        // console.log(resp,"postnext")
        if (resp) {
          setParentCategory(resp.data.data.parent);
          setInputValue(resp.data.data.nextSecondDisplayName.name);
          setInputId(resp.data.data.nextSecondDisplayName._id);
          setSelectedStatus(resp.data.data.status);
          setCategory(resp.data.data.subParent);
          setNextCategory({
            _id: resp.data.data.secondDisplayName._id,
            name: resp.data.data.secondDisplayName.name,
          });
          setSeoUrl(resp.data.data.nextSecondDisplayName.seoUrl);
          setSeoTitle(resp.data.data.nextSecondDisplayName.seoTitle);
          setSeoDescription(
            resp.data.data.nextSecondDisplayName.seoDescription
          );
          setSeoKeywords(resp.data.data.nextSecondDisplayName.seoKeywords);
          console.log(nextCategory, "mnb");
          // setCategory(resp.data.data.parent);
          // setSelectedCategory(resp.data.data.parent)
        }
      } catch (error) {
        // Handle error, e.g., show an error toast message
      }
    };

    fetchSubCategory();
  }, [dispatch, id.id]);
  console.log(subNextCategoryReducer, "id");
  return (
    <div>
      <div className="">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id
              ? "Edit Secondary Sub Category"
              : "Add Secondary Sub Category"}
          </div>
          <div className="card-body">
            <form>
              <div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect1">
                    Select Parent Category <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    value={parentCategory}
                    onChange={(event) => {
                      handleParentCategoryChange(event.target.value);
                    }}
                  >
                    <option value="">Select Parent Category</option>
                    {categoryData?.data?.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.displayName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect2">
                    Select Sub Category <span className="req-feild">*</span>
                  </label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    value={category}
                    onChange={(event) => {
                      handleCategoryChange(event.target.value);
                    }}
                  >
                    <option value="">Select Sub Category</option>
                    {subCategoryData &&
                      subCategoryData.data.subcategories?.map((item) => (
                        <>
                          <option key={item._id} value={item._id}>
                            {item.displayName}
                          </option>
                        </>
                      ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleFormControlSelect2">
                    Select Secondary Sub Category{" "}
                    <span className="req-feild">*</span>
                  </label>
                  {/* <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    value={nextCategory}
                    onChange={(event) => {
                      handleNextCategoryChange(event.target.value);
                    }}
                  >
                    <option value="">Select Secondary Sub Category</option>
                    {subNextCategoryReducer &&
                      subNextCategoryReducer.data?.map((item) => (
                        <>
                          <option
                            key={item.secondDisplayName._id}
                            value={item.secondDisplayName._id}
                          >
                            {item.secondDisplayName.name}
                          </option>
                        </>
                      ))}
                  </select> */}
                  <select
                    className="form-control"
                    id="exampleFormControlSelect2"
                    // value={nextCategory?.secondDisplayName?._id}
                    value={nextCategory?._id}
                    onChange={(event) => {
                      handleNextCategoryChange(event.target.value);
                    }}
                  >
                    {" "}
                    <option value="">Select Secondary Sub Category</option>
                    {subNextCategoryReducer &&
                      subNextCategoryReducer.data?.map((item) => (
                        <>
                          <option
                            key={item.secondDisplayName._id}
                            value={item.secondDisplayName._id}
                          >
                            {item.secondDisplayName.name}
                          </option>{" "}
                        </>
                      ))}
                    {/* <option value="">Select Secondary Sub Category</option>
  {subNextCategoryReducer &&
    subNextCategoryReducer.data?.map((item) => (
      <option
        key={item.secondDisplayName._id}
        value={item.secondDisplayName._id}
      >
        {item.secondDisplayName.name}
      </option>
    ))} */}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Add New Sub Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Sub Category"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">
                  Add Sec Sub Category Order No.
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Sub Category Order"
                  value={subCatOrder}
                  onChange={handleOrderChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">
                  SEO Url <span className="req-feild">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add SEO Url"
                  value={seoUrl}
                  onChange={handleSeoUrl}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo title"
                  value={seoTitle}
                  onChange={handleSeoTitleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo description"
                  value={seoDescription}
                  onChange={handleSeoDescriptionChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Keywords</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo seokeywords"
                  value={seoKeywords}
                  onChange={handleSeoKeywordsChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">Select Status</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <button
                type="button"
                className="btn  btn-lg"
                onClick={handleSubmit}
              >
                {id.id ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddNextCategoryNew;
