import {
    GET_NEXT_SECONDARY_SUB_CATEGORY,
    GET_NEW_SUB_CATEGORY
  } from "../Action/actionType";
  
  const initialState = "";
  
  const subNextCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NEXT_SECONDARY_SUB_CATEGORY:
        return action.payload;
      default:
        return state;
    }
  };
  
  const newSubCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_NEW_SUB_CATEGORY:
        return action.payload;
      default:
        return state;
    }
  };
  
  export { subNextCategoryReducer, newSubCategoryReducer };
  