import React, { useState, useEffect } from "react";
import "./subCategory.css";
import {
  postSubCategoryAdd,
  getSubCategorys,
  postSubCategoryUpdate,
} from "../../../store/Action/subCategoryAction";
import { getCategory } from "../../../store/Action/categoryAction";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddSubCat() {
  const [inputValue, setInputValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1"); // Default value
  const [category, setCategory] = useState("");
  const [subCatOrder, setSubCatOrder] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [formData, setFormData] = useState("");
  const dispatch = useDispatch();

  const id = useParams();
  const navigate = useNavigate();

  const { categoryData } = useSelector((state) => state);

  // console.log(categoryData.data, "categoryData");
  // console.log(category, "parent");

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleOrderChange = (event) => {
    setSubCatOrder(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleCategoryChange = (selectedCategoryId) => {
    setCategory(selectedCategoryId);
    // setSelectedCategory(event.target.value);
  };
  const handleSeoTitleChange = (event) => {
    setSeoTitle(event.target.value);
  };
  const handleSeoDescriptionChange = (event) => {
    setSeoDescription(event.target.value);
  };
  const handleSeoKeywordsChange = (event) => {
    setSeoKeywords(event.target.value);
  };
  const handleSeoUrlChange = (event) => {
    let value = event.target.value;

    // Remove special characters except hyphen
    value = value.replace(/[^a-zA-Z0-9- ]/g, "");

    // Replace spaces with hyphens
    value = value.replace(/\s+/g, "-");

    // Convert to lowercase
    value = value.toLowerCase();
    console.log(value, "url");
    // Update the state with the sanitized value
    // setUrl(value);
    setSeoUrl(value);
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault(); // Prevents the default form submission behavior

  //   // Dispatch the action with the form data
  //   const response = dispatch(
  //     postSubCategoryAdd({
  //       parent: category,
  //       displayName: inputValue,
  //       status: selectedStatus,
  //     })
  //   );

  //   if (response) {
  //     setInputValue("");
  //     // setCategory("");
  //     // toast.sucess('')
  //     console.log(response,"resp")
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!seoUrl) {
      toast.error("Seo url in required");
    } else {
      try {
        const data = {
          parent: category,
          displayName: inputValue,
          subCategoryOrder: parseInt(subCatOrder),
          status: selectedStatus,
          seoUrlSubCategory: seoUrl,
          // seoTitle:seoTitle,
          // seoDescription:seoDescription,
          // seoKeywords:seoKeywords ,
          seoTitleSubCatategory: seoTitle,
          seoDescriptionSubCatategory: seoDescription,
          seoKeywordsSubCatategory: seoKeywords,
        };

        if (id.id) {
          await dispatch(postSubCategoryUpdate(id.id, data));
          setCategory("");
          navigate("/managesubcategory");
        } else {
          await dispatch(postSubCategoryAdd(data));
          // setCategory("");
      navigate("/managesubcategory");

        }

        setInputValue("");
        selectedStatus("1");

        // setCategory("")
      } catch (error) {}
      // navigate("/managesubcategory");
    }

    
  };

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const resp = await dispatch(getSubCategorys(id.id));
        if (resp) {
          setInputValue(resp.data.data.displayName);
          setSelectedStatus(resp.data.data.status);
          setSubCatOrder(resp.data.data.subCategoryOrder);
          setSeoUrl(resp.data.data.seoUrlSubCategory ? resp.data.data.seoUrlSubCategory : "");
          setSeoTitle(resp.data.data.seoTitleSubCatategory ? resp.data.data.seoTitleSubCatategory : "");
          setSeoDescription(
            resp.data.data.seoDescriptionSubCatategory ? resp.data.data.seoDescriptionSubCatategory : ""
          );
          setSeoKeywords(
            resp.data.data.seoKeywordsSubCatategory ? resp.data.data.seoKeywordsSubCatategory : ""
          );
          setCategory(resp.data.data.parent);
          // setSelectedCategory(resp.data.data.parent)
        }
      } catch (error) {
        // Handle error, e.g., show an error toast message
      }
    };

    fetchSubCategory();
  }, [dispatch, id.id]);
  // console.log(id,"id")

  return (
    <div>
      <div className="">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id ? "Edit Sub Category" : "Add Sub Category"}
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Select Category <span className="req-feild">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={category}
                  onChange={(event) => {
                    handleCategoryChange(event.target.value);
                  }}
                >
                  {<option>Select Category</option>}
                  {/* {id.id?<option selected>{id.parent.displayName}</option>:<option disabled selected>
                    Select Category
                  </option>} */}
                  {categoryData?.data?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.displayName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Sub Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Sub Category"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Sub Category Order No.</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Sub Category Order"
                  value={subCatOrder}
                  onChange={handleOrderChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="seoUrl">
                  SEO URL <span className="req-feild">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="seoUrl"
                  aria-describedby="emailHelp"
                  value={seoUrl}
                  onChange={handleSeoUrlChange}
                  placeholder="Seo URL"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Seo Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo title"
                  value={seoTitle}
                  onChange={handleSeoTitleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo description"
                  value={seoDescription}
                  onChange={handleSeoDescriptionChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Keywords</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo seokeywords"
                  value={seoKeywords}
                  onChange={handleSeoKeywordsChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">Select Status</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <button
                type="button"
                className="btn btn-lg"
                onClick={handleSubmit}
              >
                {id.id ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
