// import React from 'react';

// const Pagination = ({ currentPage, totalPages, prevPage, nextPage }) => {
//   // Function to generate an array of page numbers
//   const getPageNumbers = () => {
//     const pagesToShow = 3; // Number of page numbers to show on each side of the current page
//     const rangeStart = Math.max(1, currentPage - pagesToShow); // Start of the range
//     const rangeEnd = Math.min(totalPages, currentPage + pagesToShow); // End of the range

//     const pageNumbers = [];
//     for (let i = rangeStart; i <= rangeEnd; i++) {
//       pageNumbers.push(i);
//     }
//     return pageNumbers;
//   };

//   return (
//     <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
//       <div className="pagination">
//         {/* Previous page button */}
//         <button
//           className="pagination-btn"
//           onClick={prevPage}
//           disabled={currentPage === 1}
//         >
//           Prev
//         </button>

//         {/* Page numbers */}
//         {getPageNumbers().map((pageNumber) => (
//           <button
//             key={pageNumber}
//             className={`pagination-btn ${currentPage === pageNumber ? 'active' : ''}`}
//             onClick={() => nextPage(pageNumber)}
//           >
//             {pageNumber}
//           </button>
//         ))}

//         {/* Next page button */}
//         <button
//           className="pagination-btn"
//           onClick={nextPage}
//           disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Pagination;
import React, { useState } from "react";

const Pagination = ({
  currentPage,
  totalPages,
  prevPage,
  nextPage,
  shiftForward,
  shiftBackward,
  goToPage,
  news,
}) => {
  const [pageNumberInput, setPageNumberInput] = useState("");

  // Function to generate an array of page numbers
  const getPageNumbers = () => {
    const pagesToShow = 3; // Number of page numbers to show on each side of the current page
    const rangeStart = Math.max(1, currentPage - pagesToShow); // Start of the range
    const rangeEnd = Math.min(totalPages, currentPage + pagesToShow); // End of the range

    const pageNumbers = [];
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  // Handler for input change
  const handleInputChange = (e) => {
    setPageNumberInput(e.target.value);
  };

  // Handler for go to page button click
  const handleGoToPage = () => {
    const pageNumber = parseInt(pageNumberInput);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      goToPage(pageNumber);
      setPageNumberInput("");
    }
  };
  console.log(news, "newsdata");

  return (
    <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
      <div className="pagination">
        {/* Shift backward button */}
        {shiftForward && (
          <button
            className="pagination-btn"
            onClick={shiftBackward}
            disabled={currentPage <= 10}
          >
            Shift -10
          </button>
        )}

        {/* Previous page button */}
        <button
          className="pagination-btn"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        {/* Page numbers */}
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            className={`pagination-btn ${
              currentPage === pageNumber ? "active" : ""
            }`}
            onClick={() => nextPage(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}

        {/* Next page button */}
        <button
          className="pagination-btn"
          onClick={nextPage}
          //   disabled={currentPage === totalPages}
          disabled={news.length < 12}
        >
          Next
        </button>

        {/* Shift forward button */}
        {shiftBackward && (
          <button
            className="pagination-btn"
            onClick={shiftForward}
            disabled={currentPage + 10 > totalPages}
          >
            Shift +10
          </button>
        )}

        {/* Go to page input */}
        {goToPage && (
          <input
            type="number"
            value={pageNumberInput}
            onChange={handleInputChange}
            min="1"
            max={totalPages}
            className="pagination-input"
          />
        )}

        {/* Go to page button */}
        <button className="pagination-btn" onClick={handleGoToPage}>
          Go
        </button>
      </div>
    </div>
  );
};

export default Pagination;
