import React, { useState, useEffect, useRef } from "react";
import "./profile.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

export default function UpdateProfile() {
  const [read, setRead] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);
  const role = localStorage.getItem("role");
  const mytoken = localStorage.getItem("token");
  const filePicker = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    twitterURL: "",
    linkedin: "",
    bio: "",
    userProfileImage: "",
    password: "",
  });
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    axios.get(`${apiUrl}user/${id}`).then((response) => {
      const data = response.data.data;
      setFormData({
        name: data.name || "",
        email: data.email || "",
        role: data.role || "",
        twitterURL: data.twitterUrl || "",
        linkedin: data.linkedinUrl || "",
        bio: data.bio || "",
        password: "", // Passwords are usually not fetched for security reasons
        userProfileImage: data.userProfileImage || "",
      });
      setBannerImagePreview(data.userProfileImage);
    });
  }, [apiUrl, id]);

  const onBannerImagePicked = (event) => {
    const file = event.target.files[0];
    handleFileSelection(
      file,
      setBannerImageFile,
      setBannerImagePreview,
      setBannerFileSizeError
    );
  };

  const handleFileSelection = (file, setFile, setPreview, setError) => {
    const maxSizeInKB = 5000; // Define your maximum size in KiloBytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        alert("File size exceeds the maximum allowed size.");
        setError(true);
        setFile(null);
        if (setPreview) setPreview("");
      } else {
        setFile(file);
        if (setPreview) {
          const reader = new FileReader();
          reader.onload = () => {
            setPreview(reader.result);
          };
          reader.readAsDataURL(file);
        }
        setError(false);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const payload = {
      userId: id,
      email: formData.email,
      name: formData.name,
      bio: formData.bio,
      twitterUrl: formData.twitterURL,
      linkedinUrl: formData.linkedin,
    };
    if (bannerImagePreview) {
      payload.userProfileImage = bannerImagePreview;
    }
    if (oldPassword && newPassword) {
      payload.oldPassword = oldPassword;
      payload.newPassword = newPassword;
    }
    try {
      const headers = { Authorization: `Bearer ${mytoken}` };
      const response = await axios.put(`${apiUrl}user/updateUser`, payload, {
        headers,
      });
      if (response) {
        toast.success("Profile Updated Successfully !");
        setRead(true);
      }
      console.log("Profile updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //   console.log(oldPassword, newPassword, "passss");
  return (
    <>
      <div className="line"></div>
      <div className="card">
        <div className="card-header">Update Profile</div>
        <div className="card-body">
          <div className="update-container">
            <div className="update-img-container">
              <div className="img-container">
                <img src={bannerImagePreview} alt="Profile" />
              </div>
              <input
                type="file"
                id="bannerImage"
                accept="image/*"
                onChange={onBannerImagePicked}
              />
              {bannerFileSizeError && (
                <div className="error-message">
                  File size exceeds the maximum allowed size.
                </div>
              )}
            </div>
            <div className="profile-details">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="John Doe"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  readOnly={read}
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="abc@gmail.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onClick={() => {
                    if (role === "admin") {
                      alert(
                        "Contact Your SuperAdmin To Change Your Email or Password !"
                      );
                    }
                  }}
                  readOnly={role === "superadmin" ? read : true}
                />
              </div>

              <div className="form-group">
                <input
                  type="url"
                  className="form-control"
                  placeholder="Twitter"
                  name="twitterURL"
                  value={formData.twitterURL}
                  onChange={handleChange}
                  readOnly={read}
                />
              </div>
              <div className="form-group">
                <input
                  type="url"
                  className="form-control"
                  placeholder="LinkedIn"
                  name="linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                  readOnly={read}
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="6"
                  placeholder="Enter Bio in (max 100 words)."
                  name="bio"
                  value={formData.bio}
                  readOnly={read}
                  onChange={handleChange}
                />
              </div>
              {role === "superadmin" && (
                <>
                  <div className="form-group">
                    <label htmlFor="">Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Old Password"
                      name="password"
                      value={oldPassword}
                      onChange={(e) => {
                        setOldPassword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      name="password"
                      value={newPassword}
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                  </div>
                </>
              )}
              {!read ? (
                <button
                  onClick={handleUpdate}
                  type="button"
                  className="btn btn-light"
                >
                  Update Profile
                </button>
              ) : (
                <button
                  onClick={() => setRead(false)}
                  type="button"
                  className="btn btn-light"
                >
                  Edit Profile
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// import React, { useState, useEffect, useRef } from "react";
// import "./profile.css";
// import { useParams } from "react-router-dom";
// import axios from "axios";

// export default function UpdateProfile() {
//   const [read, setRead] = useState(true);
//   const [oldPassword, setOldPassword] = useState("");
//   const [newPassword, setNewPassword] = useState("");
//   const [userProfileImage, setUserProfileImage] = useState(null);
//   const [bannerImagePreview, setBannerImagePreview] = useState("");
//   const [bannerFileSizeError, setBannerFileSizeError] = useState(false);

//   const filePicker = useRef(null); // Define the filePicker ref
//   const role = localStorage.getItem("role");
//   const mytoken = localStorage.getItem("token");
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     role: "",
//     twitterURL: "",
//     linkedin: "",
//     bio: "",
//     userProfileImage: "",
//     password: "",
//   });
//   const { id } = useParams();
//   const apiUrl = process.env.REACT_APP_BACKEND_URL;

//   useEffect(() => {
//     axios.get(`${apiUrl}user/${id}`).then((response) => {
//       const data = response.data.data;
//       setFormData({
//         name: data.name || "",
//         email: data.email || "",
//         role: data.role || "",
//         twitterURL: data.twitterUrl || "",
//         linkedin: data.linkedinUrl || "",
//         bio: data.bio || "",
//         password: "", // Passwords are usually not fetched for security reasons
//         userProfileImage: data.userProfileImage || "",
//       });
//       setBannerImagePreview(data.userProfileImage);
//     });
//   }, [apiUrl, id]);

//   const onBannerImagePicked = (event) => {
//     const fileInput = filePicker.current; // Access the filePicker ref
//     const file = fileInput.files[0];
//     const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)

//     if (file) {
//       const fileSizeInKB = file.size / 1024;

//       if (fileSizeInKB > maxSizeInKB) {
//         // File size exceeds the allowed limit
//         alert("Logo image size exceeds the maximum allowed size.");
//         fileInput.value = ""; // Clear the input field
//         setBannerImagePreview("");
//         setBannerFileSizeError(true);
//       } else {
//         setUserProfileImage(file);
//         const reader = new FileReader();
//         reader.onload = () => {
//           setBannerImagePreview(reader.result);
//           setBannerFileSizeError(false);
//         };
//         reader.readAsDataURL(file);
//       }
//     }
//   };
//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     const payload = {
//       userId: id,
//       email: formData.email,
//       name: formData.name,
//       bio: formData.bio,
//       twitterUrl: formData.twitterURL,
//       linkedinUrl: formData.linkedin,
//     };
//     if (userProfileImage) {
//       payload.userProfileImage = userProfileImage;
//     }

//     console.log(payload);
//     try {
//       const headers = { Authorization: `Bearer ${mytoken}` };
//       const response = await axios.put(`${apiUrl}user/updateUser`, payload, {
//         headers,
//       });
//       console.log("Profile updated successfully:", response.data);
//     } catch (error) {
//       console.error("Error updating profile:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   return (
//     <>
//       <div className="line"></div>
//       <div className="card">
//         <div className="card-header">Update Profile</div>
//         <div className="card-body">
//           <div className="update-container">
//             {/* <div className="update-img-container">
//               <div className="img-container">
//                 <img src={bannerImagePreview} alt="Profile" />
//               </div>
//               <input
//                 type="file"
//                 id="bannerImage"
//                 accept="image/*"
//                 onChange={onBannerImagePicked}
//               />
//               {bannerFileSizeError && (
//                 <div className="error-message">
//                   File size exceeds the maximum allowed size.
//                 </div>
//               )}
//             </div> */}
//             <div className="form-group">
//               {bannerImagePreview ? (
//                 <div className="image-preview">
//                   <img src={bannerImagePreview} alt="test alt name" />
//                 </div>
//               ) : null}
//             </div>
//             <label htmlFor="exampleText1">Upload Image</label>
//             <div className="add-img">
//               <button
//                 type="button"
//                 onClick={() => filePicker.current.click()} // Access the filePicker ref
//                 className="col-4"
//               >
//                 + Image
//               </button>
//               <input
//                 type="file"
//                 ref={filePicker}
//                 onChange={onBannerImagePicked}
//               />
//               {/* https://stage-api.iirfranking.com/s3-images/ */}
//               {bannerFileSizeError && (
//                 <div className="error-message">
//                   File size exceeds the maximum allowed size.
//                 </div>
//               )}
//             </div>
//             <br />

//             {/* {(bannerImagePreview || s3ImageId) && (
//                   <div className="image-preview">
//                     <img src={bannerImagePreview ? bannerImagePreview : `https://stage-api.iirfranking.com/s3-images/${s3ImageId}`} alt={'test alt name'} />
//                   </div>
//                 )} */}

//             <div className="profile-details">
//               <div className="form-group">
//                 <input
//                   type="text"
//                   className="form-control"
//                   placeholder="John Doe"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div className="form-group">
//                 <input
//                   type="email"
//                   className="form-control"
//                   placeholder="abc@gmail.com"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   readOnly={role === "admin"}
//                 />
//               </div>

//               <div className="form-group">
//                 <input
//                   type="url"
//                   className="form-control"
//                   placeholder="Twitter"
//                   name="twitterURL"
//                   value={formData.twitterURL}
//                   onChange={handleChange}
//                 />
//               </div>
//               <div className="form-group">
//                 <input
//                   type="url"
//                   className="form-control"
//                   placeholder="LinkedIn"
//                   name="linkedin"
//                   value={formData.linkedin}
//                   onChange={handleChange}
//                 />
//               </div>

//   <div className="form-group">
//     <textarea
//       className="form-control"
//       rows="6"
//       placeholder="Enter Bio in (max 100 words)."
//       name="bio"
//       value={formData.bio}
//       onChange={handleChange}
//     />
//   </div>
//               {role === "superadmin" && (
//                 <>
//                   <div className="form-group">
//                     <label htmlFor="">Old Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Old Password"
//                       name="password"
//                       value={oldPassword}
//                       onChange={(e) => {
//                         setOldPassword(e.target.value);
//                       }}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="">New Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="New Password"
//                       name="password"
//                       value={newPassword}
//                       onChange={(e) => {
//                         setNewPassword(e.target.value);
//                       }}
//                     />
//                   </div>
//                 </>
//               )}
//               <button onClick={handleUpdate}>Update Profile</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
