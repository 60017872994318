import React, { useState, useEffect } from "react";
import {
  postImageAdd,
  getImage,
  postImageUpdate,
} from "../../../store/Action/imageAction";
import { getCategory } from "../../../store/Action/categoryAction";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./image.css";

export default function AddImage() {
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState("");
  const [heading, setHeading] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const { categoryData } = useSelector((state) => state);
  const [formData, setFormData] = useState("");
  const id = useParams();
  const navigate = useNavigate();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };
  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  // console.log(categoryData,"asedrftgyhujnmkl")

  const handleHeadingChange = (event) => {
    setHeading(event.target.value);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleCategoryChange = (selectedCategoryId) => {
    setCategory(selectedCategoryId);
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault(); // Prevents the default form submission behavior
  //   // Dispatch the action with the form data
  //   const formData = new FormData();
  //   formData.append("postImages", imageFile);
  //   formData.append("title", title);
  //   formData.append("heading", heading);
  //   formData.append("category", [JSON.stringify( category)],);

  //   const response = dispatch(
  //     postImageAdd(formData)
  //   );
  //   if (response) {
  //     // setInputValue('')
  //     setImageFile("")
  //     setTitle("");
  //     setHeading("");
  //     setCategory("");

  //     // toast.sucess('')
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("postImages", imageFile);
      formData.append("title", title);
      formData.append("heading", heading);
      if (id.id) {
        formData.append("category", [JSON.stringify(id.id)]);
      } else {
        formData.append("category", [JSON.stringify(category)]);
      }
      

      if (id.id) {
        await dispatch(postImageUpdate(id.id, formData));
        setCategory("");
        navigate("/manageimage");
      } else {
        await dispatch(postImageAdd(formData));
        setCategory("");
      }

      setImageFile("");
      setTitle("");
      setHeading("");
      // setCategory("");
    } catch (error) {}
  };

  console.log(imageFile);
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const resp = await dispatch(getImage(id.id));
        if (resp) {
          setTitle(resp.data.data.title);
          setHeading(resp.data.data.heading);
          setCategory(resp.data.data.id);
          setImageFile(resp.data.data.thumbnail);

          // setSelectedCategory(resp.data.data.parent)
        }
      } catch (error) {
        // Handle error, e.g., show an error toast message
      }
    };

    fetchImage();
  }, [dispatch, id.id,handleSubmit]);

  return (
    <div>
      <div className="addImage">
        <div className="line"></div>
        <div className="card">
          <div className="card-header fw-800">
            {id.id ? "Edit Image" : "Add Image"}
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                {/* <label htmlFor="exampleText1">Heading</label> */}
                <input
                  type="file"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder=""
                  onChange={handleFileUpload}
                  style={{ width: "7rem" }}
                />
                {imageFile && (
                  <div id="imageContainer">
                    <img
                      id="uploadedImage"
                      src={id.id ? imageFile : URL.createObjectURL(imageFile)}
                      // {imageFile instanceof File ? URL.createObjectURL(imageFile) : imageFile}
                      alt="Uploaded Image"
                    />
                  </div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Title"
                  value={title}
                  onChange={handleTitleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Heading</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Heading"
                  value={heading}
                  onChange={handleHeadingChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Select Category
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={category}
                  onChange={(event) => handleCategoryChange(event.target.value)}
                >
                  <option disabled selected>
                    Select Category
                  </option>
                  {categoryData?.data?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.displayName}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="submit"
                className="btn  btn-lg"
                onClick={handleSubmit}
              >
                {id.id ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
