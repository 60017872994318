import React, { useState, useEffect } from "react";
import "./category.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  getCategorys,
  postCategoryAdd,
  postCategoryUpdate,
} from "../../../store/Action/categoryAction";
import { toast } from "react-toastify";

export default function AddCategory() {
  const [inputValue, setInputValue] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1");
  const [formData, setFormData] = useState("");
  const [catOrder, setCatOrder] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [seoKeywords, setSeoKeywords] = useState("");
  const dispatch = useDispatch();
  // const history = useHistory();
  const id = useParams();
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleOrderChange = (event) => {
    setCatOrder(event.target.value);
  };

  const handleSeoUrlChange = (event) => {
    let value = event.target.value;

    // Remove special characters except hyphen
    value = value.replace(/[^a-zA-Z0-9- ]/g, "");

    // Replace spaces with hyphens
    value = value.replace(/\s+/g, "-");

    // Convert to lowercase
    value = value.toLowerCase();
    console.log(value, "url");
    if (!value) {
      toast.error("Seo Url is required");
    }
    // Update the state with the sanitized value
    // setUrl(value);
    setSeoUrl(value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleSeoTitleChange = (event) => {
    setSeoTitle(event.target.value);
  };
  const handleSeoDescriptionChange = (event) => {
    setSeoDescription(event.target.value);
  };
  const handleSeoKeywordsChange = (event) => {
    setSeoKeywords(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!seoUrl) {
      toast.error("Seo url is required");
    } else {
      try {
        const data = {
          displayName: inputValue,
          seoUrl: seoUrl,
          status: selectedStatus,
          categoryOrder: parseInt(catOrder),
          seoTitle: seoTitle,
          seoDescription: seoDescription,
          seoKeywords: seoKeywords,
        };
        if (id.id) {
          await dispatch(postCategoryUpdate(id.id, data));
          navigate("/managecategory");
        } else {
          await dispatch(postCategoryAdd(data));
          navigate("/managecategory");
        }

        setInputValue("");
      } catch (error) {}
    }
  };
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const resp = await dispatch(getCategorys(id.id));
        if (resp) {
          setInputValue(resp.data.data.displayName);
          setCatOrder(
            resp.data.data.categoryOrder ? resp.data.data.categoryOrder : ""
          );
          setSeoUrl(resp.data.data.seoUrl ? resp.data.data.seoUrl : "");
          setSeoTitle(resp.data.data.seoTitle ? resp.data.data.seoTitle : "");
          setSeoDescription(
            resp.data.data.seoDescription ? resp.data.data.seoDescription : ""
          );
          setSeoKeywords(
            resp.data.data.seoKeywords ? resp.data.data.seoKeywords : ""
          );
          setSelectedStatus(resp.data.data.status);
        }
      } catch (error) {
        // Handle error, e.g., show an error toast message
      }
    };

    fetchCategory();
  }, [dispatch, id.id]);
  console.log(formData, "formData");
  return (
    <div>
      <div className="">
        <div className="line"></div>
        <div className="card">
          <div className="card-header">
            {id.id ? "Edit Category" : "Add Category"}
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Category</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Add Category"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Add Category Order No.</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Add Sub Category Order"
                  value={catOrder}
                  onChange={handleOrderChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="seoUrl">
                  SEO URL <span className="req-feild">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="seoUrl"
                  aria-describedby="emailHelp"
                  value={seoUrl}
                  required={true}
                  onChange={handleSeoUrlChange}
                  placeholder="Seo URL"
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">Seo Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo title"
                  value={seoTitle}
                  onChange={handleSeoTitleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo description"
                  value={seoDescription}
                  onChange={handleSeoDescriptionChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Seo Keywords</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="seo seokeywords"
                  value={seoKeywords}
                  onChange={handleSeoKeywordsChange}
                />
              </div>
              {/* <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Select Status</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div> */}
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">Select Status</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <button
                type="button"
                className="btn  btn-lg"
                onClick={handleSubmit}
              >
                {id.id ? "Update" : "Add"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
