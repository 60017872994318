import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCategory } from "../../../store/Action/categoryAction";
import { getSubCategoryByParent } from "../../../store/Action/subCategoryAction";
import { getSecondarySubCategoryByParent } from "../../../store/Action/secondarySubCategoryAction";
import {
  postCollegeData,
  postCollegeUpdate,
  getCollegeId,
} from "../../../store/Action/admissionAction";
import { useParams, useNavigate } from "react-router-dom";
import "./admission.css";

export default function AddAdmission() {
  const dispatch = useDispatch();
  const [collageName, setCollageName] = useState("");
  const [collageType, setCollageType] = useState("");
  const [category, setCategory] = useState("");
  const [parentCategory, setParentCategory] = useState("");
  const [subParentCategory, setSubParentCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("1");
  const [overview, setOverview] = useState("");
  const [courses, setCourses] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [iirfRank, setIirfRank] = useState("");
  const [nirfRank, setNirfRank] = useState("");
  const [avgpackage, setAvgPackage] = useState("");
  const [year, setYear] = useState("");

  const [s3ImageId, setS3ImageId] = useState("");
  const [selectedBannerImageFile, setSelectedBannerImageFile] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState("");
  const [bannerFileSizeError, setBannerFileSizeError] = useState(false);

  const { subCategoryData } = useSelector((state) => state);
  const { secondarySubCategoryData } = useSelector((state) => state);

  const filePicker = useRef(null); // Define the filePicker ref

  const { categoryData } = useSelector((state) => state);
  const id = useParams();
  const navigate = useNavigate();

  const handleParentCategoryChange = (selectedCategoryId) => {
    setParentCategory(selectedCategoryId);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };
  const handleIirfRankChange = (event) => {
    if (event.target.value === "") {
      setIirfRank(event.target.value);
    } else {
      const val = event.target.value;
      setIirfRank(parseInt(val, 10));
      console.log(iirfRank, "hiuu");
    }
  };
  const handleNirfRankChange = (event) => {
    if (event.target.value === "") {
      setNirfRank(event.target.value);
    } else {
      const val = event.target.value;
      setNirfRank(parseInt(val, 10));
      console.log(nirfRank, "hiuu");
    }
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const handlePackageChange = (event) => {
    setAvgPackage(event.target.value);
  };
  const handleOverviewChange = (event) => {
    setOverview(event.target.value);
  };
  const handleCoursesChange = (event) => {
    setCourses(event.target.value);
  };
  const handleCollegeTypeChange = (event) => {
    setCollageType(event.target.value);
  };
  const handleCollegeNameChange = (event) => {
    setCollageName(event.target.value);
  };
  const handleSubParentCategoryChange = (selectedSubParentCategory) => {
    setSubParentCategory(selectedSubParentCategory);
  };
  const handleCategoryChange = (selectedCategoryId) => {
    setCategory(selectedCategoryId);
  };

  const onBannerImagePicked = (event) => {
    const fileInput = filePicker.current; // Access the filePicker ref
    const file = fileInput.files[0];
    const maxSizeInKB = 1024; // Define your maximum size in KiloBytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        // File size exceeds the allowed limit
        alert("Logo image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        setBannerImagePreview("");
        setBannerFileSizeError(true);
      } else {
        setSelectedBannerImageFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setBannerImagePreview(reader.result);
          setBannerFileSizeError(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  useEffect(() => {
    // Fetch the sub-categories based on the selected parent category
    if (parentCategory) {
      dispatch(getSubCategoryByParent(parentCategory));
    }
  }, [parentCategory, dispatch]);

  console.log(subCategoryData, secondarySubCategoryData, "dddd");

  useEffect(() => {
    // Fetch the secondary sub-categories based on the selected parent category and sub-parent category
    if (subParentCategory) {
      dispatch(getSecondarySubCategoryByParent(subParentCategory));
    }
  }, [, subParentCategory, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    // Dispatch the action with the form data

    if (!id.id) {
      let payload = {
        categoryid: parentCategory,
        subCategoryid: subParentCategory,
        secondDisplayNameId: category,
        collageName: collageName,
        collageType: collageType,
        // overview: overview,
        iirfRankyear: overview,
        nirfRankyear: year,
        // fee: fees,
        // courses: courses,
        // country: country,
        city: city,
        state: state,
        // year: year,
        status: selectedStatus,
        iirfRank: iirfRank,
        nirfRank: nirfRank,
        // avgPacakge: avgpackage,
      };
      if (selectedBannerImageFile) {
        payload.imageLogo = selectedBannerImageFile;
        // for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        //   postData.append("backgroundimg", this.form.value.backgroundimg[i]);
        // }
      }
      const response = await dispatch(postCollegeData(payload));

      if (response) {
        // setAvgPackage("");
        setCategory("");
        setCity("");
        setYear("");
        setCollageName("");
        setCollageType("");
        // setCountry("");
        // setCourses("");
        setOverview("");
        setParentCategory("");
        setIirfRank("");
        setNirfRank("");
        setSelectedStatus("");
        setState("");
        setSubParentCategory("");
        setSelectedBannerImageFile("");
        navigate("/manageadmission");
        // toast.sucess('')
      }
    } else {
      let payload = {
        categoryid: parentCategory,
        subCategoryid: subParentCategory,
        secondDisplayNameId: category,
        collageName: collageName,
        collageType: collageType,
        iirfRankyear: overview,
        nirfRankyear: year,
        // fee: fees,
        // courses: courses,
        // country: country,
        city: city,
        state: state,

        status: selectedStatus,
        iirfRank: iirfRank,
        nirfRank: nirfRank,
        // avgPacakge: avgpackage,
      };
      if (selectedBannerImageFile) {
        payload.imageLogo = selectedBannerImageFile;
        // for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        //   postData.append("backgroundimg", this.form.value.backgroundimg[i]);
        // }
      }
      await dispatch(postCollegeUpdate(id.id, payload));
      navigate("/manageadmission");
    }
  };

  useEffect(
    (i) => {
      const fetchNews = async () => {
        try {
          const resp = await dispatch(getCollegeId(id.id));
          if (resp) {
            setCategory(
              resp.secondDisplayNameId ? resp.secondDisplayNameId : ""
            );
            setParentCategory(resp.categoryid ? resp.categoryid : "");
            setSubParentCategory(resp.subCategoryid ? resp.subCategoryid : "");
            setCollageName(resp.collageName ? resp.collageName : "");
            setCollageType(resp.collageType ? resp.collageType : "");
            // setCourses(resp.course ? resp.course : "");
            // setAvgPackage(resp.avgPacakge ? resp.avgPacakge : "");
            // setFees(resp.fee ? resp.fee : "");
            setOverview(resp.iirfRankyear ? resp.iirfRankyear : "");
            // setRank(resp.rank ? resp.rank : "");
            setIirfRank(resp.iirfRank ? resp.iirfRank : "");
            setNirfRank(resp.nirfRank ? resp.nirfRank : "");
            setCity(resp?.city);
            // setCountry(resp?.country);
            setState(resp?.state);
            setYear(resp?.nirfRankyear ? resp?.nirfRankyear : "");
            setS3ImageId(resp?.imageLogo);
            // setImageUrl(resp?.image);
            // setS3ImageId(resp?.imageLogo);

            // setInputValue(resp.data.data.displayName);
            // setSelectedStatus(resp.data.data.status);
          }
        } catch (error) {
          // Handle error, e.g., show an error toast message
        }
      };
      fetchNews();
    },
    [dispatch, id.id]
  );

  console.log(bannerImagePreview, s3ImageId, "bannerimgprev");

  return (
    <div>
      <div className="addAdmission">
        <div className="line"></div>
        <div className="card">
          <div className="card-header fw-800">
            {id.id ? "Edit Admission" : "Add Admission"}
          </div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Select Category <span className="req-feild">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={parentCategory}
                  onChange={(event) =>
                    handleParentCategoryChange(event.target.value)
                  }
                >
                  <option disabled value="">
                    Select Category
                  </option>
                  {categoryData?.data?.map((item, i) => (
                    <option key={i} value={item._id}>
                      {item.displayName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">
                  Select Sub Category <span className="req-feild">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={subParentCategory}
                  onChange={(event) => {
                    // handleCategoryChange(event.target.value);
                    handleSubParentCategoryChange(event.target.value);
                  }}
                >
                  <option value="">Select Sub Category</option>
                  {subCategoryData &&
                    subCategoryData.data.subcategories?.map((item) => (
                      <>
                        <option key={item._id} value={item._id}>
                          {item.displayName}
                        </option>
                      </>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">
                  Select Secondary Sub Category{" "}
                  <span className="req-feild">*</span>
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={category}
                  onChange={(event) => {
                    handleCategoryChange(event.target.value);
                  }}
                >
                  <option value="">Select Secondary Sub Category</option>
                  {secondarySubCategoryData &&
                    secondarySubCategoryData.data?.map((item) => (
                      <>
                        <option
                          key={item?.secondDisplayName?._id}
                          value={item?.secondDisplayName?._id}
                        >
                          {item.secondDisplayName?.name || "N/A"}
                        </option>
                      </>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">College Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="college name"
                  value={collageName}
                  onChange={handleCollegeNameChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">
                  College Type
                  {/* <span className="req-feild">*</span> */}
                </label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={collageType}
                  onChange={handleCollegeTypeChange}
                >
                  <option value="">Select Secondary Sub Category</option>

                  <option value="Government">Government</option>
                  <option value="Private">Private</option>
                </select>
              </div>

              {/* Image Picker Starts Here */}
              <div className="form-group">
                <label htmlFor="exampleText1">Upload Image</label>
                <div className="add-img">
                  <button
                    type="button"
                    onClick={() => filePicker.current.click()} // Access the filePicker ref
                  >
                    + Image
                  </button>
                  <input
                    type="file"
                    ref={filePicker}
                    onChange={onBannerImagePicked}
                  />
                  {bannerFileSizeError && (
                    <div className="error-message">
                      File size exceeds the maximum allowed size.
                    </div>
                  )}
                </div>

                {/* {(bannerImagePreview || s3ImageId) && (
                  <div className="image-preview">
                    <img src={bannerImagePreview ? bannerImagePreview : `https://stage-api.iirfranking.com/s3-images/${s3ImageId}`} alt={'test alt name'} />
                  </div>
                )} */}
                {bannerImagePreview || s3ImageId ? (
                  <div className="image-preview">
                    <img
                      src={
                        bannerImagePreview
                          ? bannerImagePreview
                          : `https://stage-api.iirfranking.com/s3-images/${s3ImageId}`
                      }
                      alt="test alt name"
                    />
                  </div>
                ) : null}
              </div>
              {/* Image Picker Ends Here */}

              {/* <div className="form-group">
                <label htmlFor="exampleText1">Country</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Country"
                  value={country}
                  onChange={handleCountryChange}
                />
              </div> */}

              <div className="form-group">
                <label htmlFor="exampleText1">State</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="State"
                  value={state}
                  onChange={handleStateChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="City"
                  value={city}
                  onChange={handleCityChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">IIRF Rank Year</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="year"
                  value={overview}
                  onChange={handleOverviewChange}
                />
              </div>

              {/* <div className="form-group">
                <label htmlFor="exampleText1">Courses</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Courses"
                  value={courses}
                  onChange={handleCoursesChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleText1">Average Package</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Average Package"
                  value={avgpackage}
                  onChange={handlePackageChange}
                />
              </div> */}

              <div className="form-group">
                <label htmlFor="exampleText1">IIRF Rank</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Rank"
                  value={iirfRank || ""}
                  onChange={handleIirfRankChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">NIRF Rank Year</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Year"
                  value={year}
                  onChange={handleYearChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleText1">NIRF Rank</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleText1"
                  aria-describedby="emailHelp"
                  placeholder="Rank"
                  value={nirfRank || ""}
                  onChange={handleNirfRankChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleFormControlSelect2">Select Status</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect2"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </form>
            <button
              type="submit"
              className="btn  btn-lg"
              onClick={handleSubmit}
            >
              {id.id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
