import { ALERT, GET_SUB_CATEGORY, REMOVE_ALERT } from "./actionType";
import {
  getDataAPI,
  postDataAPI,
  deleteData,
  patchDataApi,
} from "../../services/apiWrapper";

import { toast } from "react-toastify";

export const postSubCategoryAdd = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postDataAPI(`sub-category`, obj));
    if (response) {
      toast.success("Sub-Category Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
export const getSubCategory = () => async (dispatch) => {
  try {
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });

    // Include the 'id' as a parameter in the API URL
    const response = await dispatch(getDataAPI(`sub-category`));

    dispatch({ type: GET_SUB_CATEGORY, payload: response.data });
    console.log(response, "category");
  } catch (error) {
    // dispatch({ type: ALERT, payload: { error: error.message } });
    console.warn(error);
    setTimeout(() => {
      dispatch({ type: REMOVE_ALERT });
    }, 5000);
  }
};
export const getSubCategoryByParent = (parent) => async (dispatch) => {
  try {
    dispatch({
      type: ALERT,
      payload: { loading: true },
    });
    // Include the 'parentId' as a query parameter in the API URL
    // const response = await axios.get(`sub-category`);
    const response = await dispatch(getDataAPI(`sub-category?parent=${parent}`));
    dispatch({ type: GET_SUB_CATEGORY, payload: response.data });
    console.log(response, "category");
  } catch (error) {
    // Handle the error
    console.warn(error);
    setTimeout(() => {
      dispatch({ type: REMOVE_ALERT });
    }, 5000);
  }
};
export const postSubCategoryManage = (queryParams) => async (dispatch) => {
  try {
    // Assuming queryParams is an object with keys like "currentPage" and "itemsPerPage"
    const queryParamsString = new URLSearchParams(queryParams).toString();

    const response = await dispatch(
      postDataAPI(`sub-category/getsubcategory?${queryParamsString}`)
    );

    if (response) {
      // toast.success('Category Added successfully');
      return response;
    }
  } catch (error) {
    //   toast.error('Please fill all fields');
    return false;
  }
};

export const deleleteSubCategory = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(deleteData(`sub-category/${id}`));
    if (response) {
      toast.success("Sub Category deleted Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getSubCategorys = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`sub-category/${id}`));
    if (response) {
      return response;
    }
  } catch (error) {
    // toast.error('please fill all field');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
// http://127.0.0.1:8080/category/64dddda1067e95cd77eb87c1
export const postSubCategoryUpdate = (id, data) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(patchDataApi(`sub-category/${id}`, data));
    if (response) {
      toast.success("Category updated Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
