import { getDataAPI, postDataAPI,deleteData,patchDataApi } from "../../services/apiWrapper";

import { toast } from "react-toastify";

export const postImageAdd = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postDataAPI(`photos/postimage`, obj));
    if (response) {
      toast.success("Image Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};


export const postImageManage = (queryParams) => async (dispatch) => {
  try {
    // Assuming queryParams is an object with keys like "currentPage" and "itemsPerPage"
    const queryParamsString = new URLSearchParams(queryParams).toString();

    const response = await dispatch(
      postDataAPI(`photos/getphoto?${queryParamsString}`)
    );

    if (response) {
      // toast.success('Category Added successfully');
      return response;
    }
  } catch (error) {
    //   toast.error('Please fill all fields');
    return false;
  }
};

export const deletePhoto = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(deleteData(`photos/${id}`));
    if (response) {
      toast.success("Image deleted Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const getImage = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`photos/${id}`));
    if (response) {
      return response;
    }
  } catch (error) {
    // toast.error('please fill all field');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
// http://127.0.0.1:8080/category/64dddda1067e95cd77eb87c1
export const postImageUpdate = (id, data) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(patchDataApi(`photos/${id}`, data));
    if (response) {
      toast.success("Category updated Successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
