import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./preview.css";
import moment from "moment";

export default function Preview() {
  const previewData = useSelector((state) => state.previewData);
  const imgUrl = "https://api.educationpost.in/s3-images";
  console.log(previewData, "previewData");
  return (
    <div className="container-fluid">
      <div className="container opinion-main post-page-main-container">
        {/* <div className="postPageAdContainer">
          <a href="/newsletter">
            <img src="/images/postPage.svg" alt="maryville university" />
          </a>
        </div> */}
        <div className="opinion-container">
          <div className="opinion-left mt-5">
            <div className="post-heading-container mt-2">
              <h1 className="postHeading">{previewData?.heading}</h1>
              <p style={{ fontStyle: "italic", color: "#6b6b6b" }}>
                {previewData?.description}
              </p>
              <div className="postPageByLine">
                <p className="author-details mx-1">
                  <span
                    className="author-name"
                    onClick={async () => {
                      //   handleAuthorClick();
                    }}
                    // style={{ cursor: "pointer" }}
                  >
                    {previewData?.author?.name}
                  </span>
                  <span className="news-time">
                    {/* {timeAgo(previewData?.publishDate)} */}
                    {moment(previewData?.publishDate).format(
                      "DD MMMM YYYY HH:mm"
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div className="postPage-main">
              <div className="big-img-container">
                <img
                  // loading="lazy"
                  className="img-main"
                  src={
                    previewData?.bannerimage
                      ? `${imgUrl}/${previewData?.bannerimage}`
                      : "/images/news.jpeg"
                  }
                  alt={previewData?.imgAlt ? previewData?.imgAlt : ""}
                />
                {previewData?.imgCaption && (
                  <p className="post-img-caption">{previewData?.imgCaption}</p>
                )}
              </div>

              <p
                className="mt-2"
                id="post-content-ck-edit"
                dangerouslySetInnerHTML={{
                  __html: previewData?.content,
                }}
              />
            </div>
          </div>

          <hr className="mx-2" />
          <div className="opinion-right">
            {/* <OpinionRight
          latestNews={newsData}
          // timeAgo={timeAgo}
          topNews={topnews}
        /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
