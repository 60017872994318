import { getDataAPI, deleteData, postFormDataAPI, patchFormDataApi } from "../../services/apiWrapper";

import { toast } from "react-toastify";

export const postCollegeData = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(postFormDataAPI(`admission`, obj));
    if (response) {
      toast.success("College Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error('Fill all the details!');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
export const getCollegeData = (obj) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`admission?page=${obj.page}&limit=${obj.perPage}&searchQuery=${obj.searchQuery}`, obj));
    if (response) {
      // toast.success("News Added successfully");
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    // toast.error("please fill all field");
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
export const deleleteCollege = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(deleteData(`admission/${id}`));
    if (response) {
      toast.success('News deleted Successfully');
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error('Something went wrong');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};
export const getCollegeId = (id) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(getDataAPI(`admission/${id}`));
    if (response) {
      return response.data.data;
    }
  } catch (error) {
    // toast.error('please fill all field');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
};

export const postCollegeUpdate = (id, data) => async (dispatch) => {
  try {
    //   dispatch({ type: ALERT, payload: { loading: true } });
    const response = await dispatch(patchFormDataApi(`admission/${id}`, data));
    if (response) {
      toast.success('Category updated Successfully');
      // dispatch({ type: ALERT, payload: { loading: false } });
      return response.data;
    }
  } catch (error) {
    toast.error('please fill all field');
    //   dispatch({ type: ALERT, payload: { loading: false } });
    return false;
  }
}; 