import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import react, { useState } from "react";
import "./App.css";
import Home from "./Component/Home";
import AddCategory from "./Component/Dashboard/category/AddCategory";
import ManageCategory from "./Component/Dashboard/category/ManageCategory";
import AddSubCat from "./Component/Dashboard/subCategory/AddSubCat";
import ManageSubCat from "./Component/Dashboard/subCategory/ManageSubCat";
import AddImage from "./Component/Dashboard/image/AddImage";
import ManageImage from "./Component/Dashboard/image/ManageImage";
import AddNews from "./Component/Dashboard/news/AddNews";
import ManageNews from "./Component/Dashboard/news/ManageNews";
import Navbar from "./Component/Navbar";
import Sidebar from "./Component/Sidebar";
import Dashboard from "./Component/Dashboard";
import { ToastContainer } from "react-toastify";
import AddSecondarySubCategory from "./Component/Dashboard/secondartsubcategory/AddSecondarySubCategory";
import ManageSecondarySubCategory from "./Component/Dashboard/secondartsubcategory/ManageSecondarySubCategory";
import "react-toastify/dist/ReactToastify.css";
import LogIn from "./Component/Dashboard/logIn/LogIn";
import SignIn from "./Component/Dashboard/signIn/SignIn";
import AddAdmission from "./Component/Dashboard/admission/AddAdmission";
import ManageAdmission from "./Component/Dashboard/admission/ManageAdmission";
import AddNextCategoryNew from "./Component/Dashboard/nextSecondarycategory/AddNextCategoryNew";
import ManageNextCategoryNew from "./Component/Dashboard/nextSecondarycategory/ManageNextCategoryNew";
import AddVideos from "./Component/Dashboard/video/AddVideos";
import ManageVideos from "./Component/Dashboard/video/ManageVideos";
import AddMagzine from "./Component/Dashboard/magzine/AddMagzine";
import ManageMagzine from "./Component/Dashboard/magzine/ManageMagzine";

import Preview from "./Component/Preview";
import EditorComponent from "./Coeditorx.editor";
import UpdateProfile from "./Component/Dashboard/profile/UpdateProfile";
import UserList from "./Component/Dashboard/userList/UserList";
import AuthorPost from "./Component/Dashboard/authorPost/AuthorPost";
// Higher Order Component
const expirationDate = localStorage.getItem("expiration");

const token = localStorage.getItem("token");
const ProtectedRoute = ({ element, toggleSidebar, sidebarActive }) => {
  const token = localStorage.getItem("token");
  const expDate = new Date(expirationDate);

  // Get the current date and time
  const currentDate = new Date();

  // Compare the expiration date with the current date
  // if (currentDate >= expDate) {
  //   // return true; // Expiration date is valid
  //   return <Navigate to="/login" />;
  // }

  // Check if the user is authenticated
  if (!token) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/login" />;
  }

  // You can add additional logic to check the validity of the token here if needed

  // Render the protected component with Sidebar and Navbar
  // const [sidebarActive, setSidebarActive] = useState(false);

  // // Function to toggle the sidebar
  // const toggleSidebar = () => {
  //   console.log("Toggle Sidebar clicked");
  //   setSidebarActive(!sidebarActive);
  // };

  // console.log
  return (
    <>
      <Sidebar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} />
      <div id="content">
        <Navbar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} />
        {element}
      </div>
    </>
  );
};
// console.log(expirationDate, token, "expirationDate");
function App() {
  const [sidebarActive, setSidebarActive] = useState(false);

  // Function to toggle the sidebar
  const toggleSidebar = () => {
    console.log("Toggle Sidebar clicked");
    setSidebarActive(!sidebarActive);
  };

  return (
    <div>
      {/* <div className="wrapper">
      <Sidebar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} />
      <div id="content">
      <Navbar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar}/>
      <Dashboard  />
      </div>
    </div> */}
      <ToastContainer />
      <Router>
        <div className="wrapper">
          {/* <Sidebar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} /> */}
          {/* <div id="content"> */}
          {/* <Navbar sidebarActive={sidebarActive} toggleSidebar={toggleSidebar} /> */}
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute
                  element={<Dashboard />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addcategory"
              element={
                <ProtectedRoute
                  element={<AddCategory />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editcategory/:id"
              element={
                <ProtectedRoute
                  element={<AddCategory />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managecategory"
              element={
                <ProtectedRoute
                  element={<ManageCategory />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addvideos"
              element={
                <ProtectedRoute
                  element={<AddVideos />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editvideos/:id"
              element={
                <ProtectedRoute
                  element={<AddVideos />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managevideos"
              element={
                <ProtectedRoute
                  element={<ManageVideos />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addmagzine"
              element={
                <ProtectedRoute
                  element={<AddMagzine />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editmagzine/:id"
              element={
                <ProtectedRoute
                  element={<AddMagzine />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managemagzine"
              element={
                <ProtectedRoute
                  element={<ManageMagzine />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addsubcategory"
              element={
                <ProtectedRoute
                  element={<AddSubCat />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editsubcategory/:id"
              element={
                <ProtectedRoute
                  element={<AddSubCat />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editSecondarysubcategory/:id"
              element={
                <ProtectedRoute
                  element={<AddSecondarySubCategory />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managesubcategory"
              element={
                <ProtectedRoute
                  element={<ManageSubCat />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editadmission/:id"
              element={
                <ProtectedRoute
                  element={<AddAdmission />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addadmission"
              element={
                <ProtectedRoute
                  element={<AddAdmission />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editphoto/:id"
              element={
                <ProtectedRoute
                  element={<AddImage />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/manageadmission"
              element={
                <ProtectedRoute
                  element={<ManageAdmission />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addnews"
              element={
                <ProtectedRoute
                  element={<AddNews />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editnews/:id"
              element={
                <ProtectedRoute
                  element={<AddNews />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managenews"
              element={
                <ProtectedRoute
                  element={<ManageNews />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addsecondarysubcategory"
              element={
                <ProtectedRoute
                  element={<AddSecondarySubCategory />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managesecondarysubcategory"
              element={
                <ProtectedRoute
                  element={<ManageSecondarySubCategory />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/addnextsubcategory"
              element={
                <ProtectedRoute
                  element={<AddNextCategoryNew />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/editNextSecondarysubcategory/:id"
              element={
                <ProtectedRoute
                  element={<AddNextCategoryNew />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/register"
              element={
                <ProtectedRoute
                  element={<SignIn />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/managenextsubcategory"
              element={
                <ProtectedRoute
                  element={<ManageNextCategoryNew />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/user-list"
              element={
                <ProtectedRoute
                  element={<UserList />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />

            <Route
              exact
              path="/update-profile/:id"
              element={
                <ProtectedRoute
                  element={<UpdateProfile />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />
            <Route
              exact
              path="/author-posts/:id"
              element={
                <ProtectedRoute
                  element={<AuthorPost />}
                  sidebarActive={sidebarActive}
                  toggleSidebar={toggleSidebar}
                />
              }
            />

            {/* <Route exact path="/register" element={<SignIn />} /> */}
            <Route exact path="/login" element={<LogIn />} />
            <Route exact path="/preview" element={<Preview />} />
            <Route exact path="/coeditorx-test" element={<EditorComponent />} />
          </Routes>
          {/* </div> */}
        </div>
      </Router>
    </div>
  );
}

export default App;
