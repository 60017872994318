import {
    GET_CATEGORY,
  } from "../Action/actionType";
  const initialState = "";

  const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY:
            return action.payload
        default: return state;
    }
}

export default categoryReducer;