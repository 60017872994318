/* eslint-disable no-unused-vars */
import axios from "axios";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
import { clearLocalStorage } from "../utils/localStrogaeUtil";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

export const getDataAPI = (url) => async (dispatch) => {
  try {
    let token = localStorage.getItem('token');
    const res = await axios.get(`${apiUrl}${url}`, {
      headers: {
        // "Accept-Language": currentLangCode || "en",
        "Authorization": `Bearer ${token}`,
      },
    });
    setTimeout(() => {
      //   dispatch({ type: REMOVE_ALERT });
    }, 5000);
    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      //   dispatch({ type: AUTH_FAIL });
      clearLocalStorage();
      console.log('error 401')
    }
    throw error;
  }
};

export const postDataAPI = (url, post) => async () => {
  // const navigate = useNavigate();
  let token = localStorage.getItem('token');
  try {
    const res = await axios.post(`${apiUrl}${url}`, post, {
      headers: {
        // "x-auth-token": token,
        "Authorization": `Bearer ${token}`,
        // "Accept-Language": currentLangCode || "en",
      },
    });
    // setTimeout(() => {
    //   dispatch({ type: REMOVE_ALERT });
    // }, 5000);

    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      //   dispatch({ type: AUTH_FAIL });
      clearLocalStorage();
      // navigate('/');
    }
    if (error?.response?.status === 404) {
      const flag = false;
      // toast.error("Record not found", {
      //   toastId: "unique-random-text-dssa5454",
      //   position: "bottom-left",
      // });
      return flag;
    }
    // setTimeout(() => {
    //   dispatch({ type: REMOVE_ALERT });
    // }, 3000);
    throw error;
  }
};

export const postFormDataAPI = (url, data) => async () => {

  // const navigate = useNavigate();
  let token = localStorage.getItem('token');
  try {
    let formData = new FormData();

    // Iterate over the fields in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'bannerimage' || key === 'userProfileImage') {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    const res = await axios.post(`${apiUrl}${url}`, formData, {
      headers: {
        // "x-auth-token": token,
        "Authorization": `Bearer ${token}`,
        // "Content-Type": "application/json" // Header for Form Data
        // Any other headers as needed
      },
    });
    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      // dispatch({ type: AUTH_FAIL });
      clearLocalStorage();
      // navigate('/');
    }
    if (error?.response?.status === 404) {
      const flag = false;
      return flag;
    }
    throw error;
  }
};

export const patchFormDataApi = (url, data) => async () => {
  // const navigate = useNavigate();
  let token = localStorage.getItem('token');
  try {
    let formData = new FormData();

    // Iterate over the fields in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === 'bannerimage') {
          formData.append(key, data[key]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    const res = await axios.patch(`${apiUrl}${url}`, formData, {
      headers: {
        // "x-auth-token": token,
        "Authorization": `Bearer ${token}`,
        //     "Accept-Language": currentLangCode || "en",
      },
    });
    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      //   dispatch({ type: AUTH_FAIL });
      clearLocalStorage();
      // navigate('/');
    }
    if (error?.response?.status === 404) {
      const flag = false;
      return flag;
    }
    throw error;
  }
};

export const getDataWithParams = async (url, param) => {
  const res = await axios.get(
    `${apiUrl}/${url}`,
    { params: param },
    {
      //   headers: {
      //     "x-auth-token": token,
      //     "Accept-Language": currentLangCode || "en",
      //   },
    }
  );
};

export const postFileDataAPI = (url, post) => async () => {
  // const navigate = useNavigate();
  let token = localStorage.getItem('token');
  try {
    const res = await axios.post(`${apiUrl}${url}`, post, {
      headers: {
        // "x-auth-token": token,
        "Authorization": `Bearer ${token}`,
        //     "Accept-Language": currentLangCode || "en",
      },
    });

    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      //   dispatch({ type: AUTH_FAIL });
      console.log('error');
      clearLocalStorage();
      // navigate('/');
    }
    if (error?.response?.status === 404) {
      const flag = false;
      return flag;
    }
    throw error;
  }
};

export const patchDataApi = (url, post) => async () => {
  // const navigate = useNavigate();
  let token = localStorage.getItem('token');
  try {
    const res = await axios.patch(`${apiUrl}${url}`, post, {
      headers: {
        // "x-auth-token": token,
        "Authorization": `Bearer ${token}`,
        //     "Accept-Language": currentLangCode || "en",
      },
    });

    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      //   dispatch({ type: AUTH_FAIL });
      clearLocalStorage();
      // navigate('/');
    }
    throw error;
  }
};

export const getBlogData = (url) => async () => {
  try {
    const res = await axios.get(`${apiUrl}${url}`, {
      // headers: {
      //   "Accept-Language": currentLangCode || "en",
      // },
    });
    // setTimeout(() => {
    //   dispatch({ type: REMOVE_ALERT });
    // }, 5000);
    return res;
  } catch (error) {
    if (error?.response?.status === 401) {
      console.log(error);
    }
    throw error;
  }
};

export const deleteData = (url) => async (dispatch) => {
  // const navigate = useNavigate();
  let token = localStorage.getItem('token');
  try {
    const res = await axios.delete(`${apiUrl}${url}`, {
      headers: {
        // "x-auth-token": token,
        "Authorization": `Bearer ${token}`,
        //     "Accept-Language": currentLangCode || "en",
      },
    });
    return res;
    // Dispatch success action or handle response
  } catch (error) {
    // Dispatch error action or handle error
    if (error?.response?.status === 401) {
      //   dispatch({ type: AUTH_FAIL });
      clearLocalStorage();
      // navigate('/');
    }
    throw error;
  }
}