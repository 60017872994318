import { SET_CONTENT } from "../Action/actionType";

const initialState = {
  author: "",
  heading: "",
  publishDate: "",
  url: "",
  urlType: "newurl",
  description: "",
  categoryid: "",
  subCategoryid: "",
  year: "",
  imgCaption: "",
  isDraft: false,
  imgAlt: "",
  popularPost: false,
  seoTitle: "",
  seoDescription: "",
  seoKeywords: "",
  secondDisplayNameId: "",
  nextSecondDisplayNameId: "",
  topNews: "",
  country: "",
  city: "",
  state: "",
  content: "",
  videourl: "",
  oldPubDate: "", // Adding the key for oldPubDate
  bannerimage: "", // Assuming this is the key for the banner image
};

const previewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default previewReducer;
