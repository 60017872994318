import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import {
  postSubCategoryManage,
  deleleteSubCategory,
} from "../../../store/Action/subCategoryAction";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getSubCategoryByParent } from "../../../store/Action/subCategoryAction";
import "./subCategory.css";
import { getCategory } from "../../../store/Action/categoryAction";

export default function ManageSubCat() {
  const dispatch = useDispatch();
  const [subCategories, setSubCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [deletes, setDelete] = useState(1);
  const itemsPerPage = 10; // Change this to the desired number of items per page
  const { categoryData } = useSelector((state) => state);
  const role = localStorage.getItem("role");

  // Logic to calculate total pages
  const queryParams = { page: currentPage, perPage: itemsPerPage };

  // Logic to handle pagination controls
  const nextPage = () => {
    if (subCategories.length === itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDelete = (categoryId) => {
    if (window.confirm("Are you sure you want to delete this Sub category?")) {
      const response = dispatch(deleleteSubCategory(categoryId));
      if (response) {
        setDelete(response);
      }
    }
  };
  // Fetch categories and total count from API or any data source
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await dispatch(postSubCategoryManage(queryParams));
        if (response) {
          setSubCategories(response.data.category); // Update with the correct field name from your API response
          // setTotalCount(response.data.totalCount);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchSubCategories();
  }, [dispatch, currentPage, deletes]);

  // console.log(subCategories, "abc");
  const [parentCategory, setParentCategory] = useState("");
  const handleParentCategoryChange = (selectedParentCategory) => {
    setParentCategory(selectedParentCategory);
  };
  // useEffect(()=>{
  //   dispatch(getCategory())
  //   dispatch(getSubCategoryByParent())
  // },[])
  // console.log(categoryData,"hola")

  return (
    <div>
      <div className="card">
        <div className="card-header">Manage Sub Category</div>
        {/* <div className="filter-container">
          <div className="form-group">
            <select
              className="form-control"
              id="exampleFormControlSelect1"
              value={parentCategory}
              onChange={(event) => {
                // handleCategoryChange(event.target.value);
                handleParentCategoryChange(event.target.value);
              }}
            >
              <option value="">Select Category</option>
              {categoryData?.data?.map((item, i) => (
                <option key={i} value={item._id}>
                  {item.displayName}
                </option>
              ))}
            </select>
          </div>



         

          

          <div style={{ lineHeight: "2rem", fontSize: "14px" }}>
            Filter By Category
          </div>
        </div> */}

        <div className="card-body">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Order No.</th>
                <th scope="col">Category Name</th>
                <th scope="col">Sub Category Name</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {subCategories.map((item) => (
                <tr key={item._id}>
                  <td>
                    {item.subCategoryOrder ? item.subCategoryOrder : `N/A`}
                  </td>
                  <td>
                    {item.parent ? item.parent.displayName : `Not Defined`}
                  </td>
                  <td>{item.displayName}</td>
                  {/* <td>{item.status}</td> */}
                  <td>{item.status == 1 ? "Active" : "Inactive"}</td>

                  <td>
                    <Link to={`/editsubcategory/${item._id}`}>
                      <button
                        className="action-button"
                        //  onClick={() => handleEdit(item)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>

                    {role === "superadmin" && (
                      <button
                        className="action-button"
                        onClick={() => handleDelete(item._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </td>
                  {/* Add action buttons here */}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="card-footer bg-transparent border-secondary d-flex justify-content-center">
            <div className="pagination">
              <button
                className="pagination-btn"
                onClick={prevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="current-page">{currentPage}</span>

              <button
                className="pagination-btn"
                onClick={nextPage}
                disabled={subCategories.length < itemsPerPage}
              >
                {subCategories.length < itemsPerPage ? "" : "Next"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
